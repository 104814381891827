import {Card, Col, message, Row} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBasketShopping, faBuilding, faClock, faCubes} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import Api from "../../../services/Api";

const AdminCardTotalsComponent = () => {
    const [data,setData] = useState([])

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {

        Api.get("/api/Report/card-report-totals").then(res => {
            setData(res.data)
        }).catch(err => {
            message.error(err.response.data.Detail)
        })
    }


    return(
        <Row gutter={[10,10]}>
            <Col span={12} xxl={6}>
                <Card style={{backgroundColor:'darkblue', color:"white", height:"100%"}}>
                    <Row gutter={[10,10]} style={{textAlign:"center"}}>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <FontAwesomeIcon icon={faBuilding} style={{height:50}} />
                        </Col>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <h1>{data?.toplamCari}</h1>
                            <p>Sistemde Kayıtlı Cari</p>
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={12} xxl={6}>
                <Card style={{backgroundColor:'purple', color:"white", height:"100%"}}>
                    <Row gutter={[10,10]} style={{textAlign:"center"}}>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <FontAwesomeIcon icon={faCubes} style={{height:50}} />
                        </Col>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <h1>{data?.toplamStok}</h1>
                            <p>Sistemde Kayıtlı Stok</p>
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={12} xxl={6}>
                <Card style={{backgroundColor:'green', color:"white", height:"100%"}}>
                    <Row gutter={[10,10]} style={{textAlign:"center"}}>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <FontAwesomeIcon icon={faBasketShopping} style={{height:50}} />
                        </Col>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <h1>{data?.toplamSiparis}</h1>
                            <p>Tamamlanan Siparişler</p>
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={12} xxl={6}>
                <Card style={{backgroundColor:'orange', color:"white", height:"100%"}}>
                    <Row gutter={[10,10]} style={{textAlign:"center"}}>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <FontAwesomeIcon icon={faClock} style={{height:50}} />
                        </Col>
                        <Col span={12} style={{alignSelf:"center"}}>
                            <h1>{data?.toplamOnayBekleyenSiparis}</h1>
                            <p>Onay Bekleyen Sipariş</p>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>

    )
}

export default AdminCardTotalsComponent;