import {Button, Card, DatePicker, Form, message} from "antd";
import {useContext, useEffect, useState} from "react";
import Api from "../../services/Api";
import dayjs from "dayjs";
import {AuthContext} from "../../context/AuthContext";

const AccountStatement = () => {
    const [data,setData] = useState();
    const [form] = Form.useForm();
    const {user} = useContext(AuthContext)
    const [loading, setLoading] = useState(false);


    const onFinisFailed =() => {
        message.error("Lütfen tüm alanları doldurunuz")
    }

    const getData = (values) => {
        if(user == null || user.id == null)
        {
            message.error("Cari bilgileri alınamadı")
            return false;
        }

        setLoading(true)

        Api.get("api/Report/cari-ekstre?cariId="+user.id+"&BaslangicTarih="+dayjs(values.startDate).format("YYYY-MM-DD")+"&BitisTarih="+dayjs(values.endDate).format("YYYY-MM-DD")).then((response) => {
            setData(response.data)
            setLoading(false)
        }).catch((error) => {
            message.error("Hata oluştu")
            setLoading(false)
        })
    }

    return(<div className={"mt-2"}>
        <Card title={"Hesap Ekstre Tarih Aralığı"} loading={loading}>
            <Form
                form={form}
                onFinish={getData}
                onFinishFailed={onFinisFailed}
                layout={"inline"}
                labelAlign={"left"}
                labelCol={{
                    span: 11,
                }}
                colon={true}
            >
                <Form.Item
                    label={"Başlangıç Tarihi"} style={{width:300}} name={"startDate"} rules={[{ required: true },]}>
                    <DatePicker style={{ width: '100%' }} format={"DD.MM.YYYY"}></DatePicker>
                </Form.Item>

                <Form.Item
                    label={"Bitiş Tarihi"} style={{width:300}} name={"endDate"} rules={[{ required: true },]}>
                    <DatePicker style={{ width: '100%' }} format={"DD.MM.YYYY"}></DatePicker>
                </Form.Item>


                <Form.Item>
                    <Button type={"primary"} htmlType="submit" className={"w-100"}>Raporu Getir</Button>
                </Form.Item>

            </Form>

        </Card>

        {data && data.result &&
            <Card title={"Hesap Ekstresi"} className={"mt-1"}>
                <Base64PDFViewer base64PDF={data.result}></Base64PDFViewer>
            </Card>
        }

    </div>)

}


const Base64PDFViewer = ({base64PDF}) => {
    const pdfData = `data:application/pdf;base64,${base64PDF}`;

    return (
        <iframe
            src={pdfData}
            width="100%"
            height="600px"
            title="PDF Viewer"
        />
    );
};

export default AccountStatement