import {Button, Card, Checkbox, DatePicker, Form, message, Switch} from "antd";
import {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import Api from "../../services/Api";

const AccountMaturityAnalysis = () => {
    const {user} = useContext(AuthContext)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data,setData] = useState();

    const onFinisFailed =() => {
        message.error("Lütfen tüm alanları doldurunuz")
    }

    const getData = (values) => {
        if(user == null || user.id == null)
        {
            message.error("Cari bilgileri alınamadı")
            return false;
        }

        setLoading(true)

        Api.get("api/Report/cari-vade-analiz?cariId="+user.id+"&irsaliyeDahilEt="+values.irsaliyeDahilEt+"&teslimEdilmeyenSiparisDahilEt="+values.teslimEdilmeyenSiparisDahilEt)
            .then((response) => {
                setData(response.data)
                setLoading(false)
        }).catch((error) => {
            message.error("Hata oluştu")
            setLoading(false)
        })
    }

    return(<div className={"mt-2"}>
        <Card title={"Hesap Vade Analizi"} loading={loading} >
            <Form
                form={form}
                initialValues={{irsaliyeDahilEt:false, teslimEdilmeyenSiparisDahilEt:false}}
                onFinish={getData}
                onFinishFailed={onFinisFailed}
                layout={"horizontal"}
                labelAlign={"left"}
                labelCol={{
                    span: 11,
                }}
                colon={true}
            >
                <Form.Item
                    label={"İrsaliyeleri dahil et"} valuePropName={"checked"} name={"irsaliyeDahilEt"} >
                    <Checkbox name={"irsaliyeDahilEt"}  />
                </Form.Item>

                <Form.Item
                    label={"Teslim Edilmeyen Siparişleri Dahil Et"} valuePropName={"checked"} name={"teslimEdilmeyenSiparisDahilEt"} >
                    <Checkbox name={"teslimEdilmeyenSiparisDahilEt"} />
                </Form.Item>

                <Form.Item>
                    <Button type={"primary"} htmlType="submit" className={"w-100"}>Raporu Getir</Button>
                </Form.Item>

            </Form>
        </Card>

        {data && data.result &&
            <Card title={"Hesap Vade Analizi Raporu"} className={"mt-1"}>
                <Base64PDFViewer base64PDF={data.result}></Base64PDFViewer>
            </Card>
        }
    </div>)
}

const Base64PDFViewer = ({base64PDF}) => {
    const pdfData = `data:application/pdf;base64,${base64PDF}`;

    return (
        <iframe
            src={pdfData}
            width="100%"
            height="600px"
            title="PDF Viewer"
        />
    );
};
export default AccountMaturityAnalysis;