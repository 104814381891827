import React from 'react';
import ReactDOM from 'react-dom/client';
import {ConfigProvider} from "antd";
import trTR from 'antd/locale/tr_TR';
import { RouterProvider } from "react-router-dom";
import {router} from "./App";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import {LayoutContextProvider} from "./context/LayoutContext";
dayjs.locale('tr');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <LayoutContextProvider>
        <ConfigProvider
            locale={trTR}
        >
            <RouterProvider router={router} />
        </ConfigProvider>
    </LayoutContextProvider>

);