class TokenService {

    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshTokenStr;
    }

    getLocalAccessToken() {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            return user?.accessToken;
        }
        catch (e){
            return null;
        }
    }

    updateLocalAccessToken(token, refreshToken) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.accessToken.token = token;
        user.refreshTokenStr = refreshToken;
        this.setUser(user)
    }

    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem("user");
    }


    krediKartOncesiYazdir(data){
        localStorage.setItem("krediKartOncesi", JSON.stringify(data));
    }
}

export default new TokenService();
