import {Button, Card, Col, Form, Image, Input, message, Row, Table} from "antd";
import Api, {API_URL} from "../../../../../services/Api";
import {useEffect, useState} from "react";

const SliderDetail = ({items, modalClose}) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState();
    const [coverImage, setCoverImage] = useState();
    const [dataTable, setDataTable] = useState([])

    useEffect(() => {
        getData()
        getDataTables()
    }, []);

    const getData = () => {
        form.setFieldsValue({code: items[0].code, category: items[0].category, valueType: "", desc: "", value: ""})
    }

    const getDataTables = () => {
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "Category",
                "operator": "eq",
                "value": "slider",
                "logic": "and",
                "filters": [
                    {
                        "field": "Code",
                        "operator": "eq",
                        "value": items[0].code,
                        "logic": "and"
                    }
                ]
            }
        }

        Api.post("/api/Parametres/GetListDynamic?PageIndex=0&PageSize=100",requestDto).then(res => {
            setDataTable(res.data.items)
        }).catch(err => {
            message.error("Sayfa getirilirken hata oluştu").then(err => {});
        })
    }

    const getDelete = (value) => {
        if(dataTable.length === 1){
            message.error("Son kayıt silinemez").then(r => {})
            return false
        }


        Api.delete("/api/Parametres/"+value).then(res => {
            message.success("Silme İşlemi Başarılı").then(r => {})
            getDataTables()
        }).catch(err => {
            message.error("Silme İşlemi Başarısız").then(r => {})})
    }

    const uploadCoverImageApi = (e) => {
        //daha önce fotoraf varsa o fotoğrafı silmelisin :)
        if (coverImage !== undefined) {
            Api.delete("/api/FileUpload/" + coverImage).then(res => message.success("Önceki kapak fotoğrafı silindi")).catch(err => message.error("Önceki kapak fotoğrafı silinemedi"))
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0])

        Api.post("/api/FileUpload", formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            setCoverImage(res.data.fileList[0])
            form.setFieldValue("value", res.data.fileList[0])
        }).catch(err => {
            message.error("Kapak Fotoğrafı Yüklenemedi").then(r =>{})
        })
    }

    const onFinish = (values) => {
        Api.post("/api/Parametres", values).then(res => {
            message.success("Kayıt Başarılı").then(r => {})
            getData()
            getDataTables()

        }).catch(err => {
            message.error("Kayıt Başarısız").then(r => {})
        })
    }

    const onFinishFailed = (errorInfo) => {}

    return(
        <>
            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card size={"small"} title={"Yeni Görsel Ekle"}>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            labelCol={
                                {
                                    span: 5,
                                }
                            }
                            // initialValues={items[0]}
                            onFinishFailed={onFinishFailed}
                            layout={"horizontal"}
                        >
                            <Form.Item hidden={true} label={"Parametre"} name={"category"}>
                                <Input disabled={"disabled"}></Input>
                            </Form.Item>

                            <Form.Item hidden={false} label={"Kodu"} name={"code"}>
                                <Input disabled={"disabled"}></Input>
                            </Form.Item>

                            <Form.Item label={"Açıklama"} name={"desc"}>
                                <Input></Input>
                            </Form.Item>

                            <Form.Item label={"Link"} name={"valueType"}>
                                <Input></Input>
                            </Form.Item>

                            <Form.Item label={"Görsel Dosya Adı"} name={"value"}>
                                <Input></Input>
                            </Form.Item>


                            <Form.Item label={"Dosya Yükle"}>
                                <Input accept="image/png, image/gif, image/jpeg"
                                       onChange={uploadCoverImageApi}
                                       type={"file"}>
                                </Input>
                            </Form.Item>

                            {coverImage !== undefined && coverImage !== "" &&
                                <Form.Item label={"Yüklenen"} className={"mt-1"}>
                                    <Image width={200} src={API_URL+"/uploads/"+coverImage}></Image>
                                </Form.Item>
                            }

                            <Form.Item label={" "} className={"mt-1"} colon={false}>
                                <Button type={"primary"} htmlType={"submit"}>Kaydet</Button>
                            </Form.Item>

                        </Form>
                    </Card>

                </Col>

                <Col span={24}>
                    <Card size={"small"} title={"Eklenen Görseller"}>
                        <Table dataSource={dataTable} size={"small"} pagination={false}>
                            <Table.Column title={"İşlem"} render={(_,record) => {
                                return(
                                    <Button  onClick={(e) => getDelete(record.id)} type={"danger"} size={"small"}>Sil</Button>
                                )
                            }}></Table.Column>
                            <Table.Column title={"Değer"} dataIndex={"value"} render={(_,record) => {
                                return(
                                    <Image width={200} src={API_URL+"/uploads/"+record.value}></Image>
                                )
                            } }></Table.Column>
                            <Table.Column title={"Açıklama"} dataIndex={"desc"}></Table.Column>
                        </Table>
                    </Card>

                </Col>
            </Row>
        </>

    )
}


export default SliderDetail