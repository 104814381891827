import {Button, Card, message, Space, Table} from "antd";
import Api from "../../../services/Api";
import {useContext, useEffect, useState} from "react";
import Column from "antd/es/table/Column";
import {AuthContext} from "../../../context/AuthContext";

const OrderManagementPage = () => {
    const [siparis,setSiparis] = useState([]);
    const [siparisLoading, setSiparisLoading] = useState(true);
    const {user} = useContext(AuthContext)

    const [bekleyenSiparis, setBekleyenSiparis] = useState([]);
    const [bekleyenSiparisLoading, setBekleyenSiparisLoading] = useState(true);

    if(!user || user.id < 1){
        return (<div>Cari hesabı yok, giriş yapın</div>)
    }

    const getSiparis = () => {
        const reqData = {
            "sort": [
                {
                    "field": "createdDate",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "CariId",
                "operator": "eq",
                "value": `${user.id}`,
                "logic": "and"
            }
        }

        Api.post("api/Siparis/GetListDynamic?PageIndex=0&PageSize=100", reqData).then(res => {
            setSiparis(res.data.items)
            setSiparisLoading(false)
        }).catch(err => {
            message.error("Siparişler getirilemedi").then(r => {})
            setSiparisLoading(false)
        })
    }

    const getBekleyenSiparis = () => {

        const reqData = {
            "sort": [
                {
                    "field": "createdDate",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "CariId",
                "operator": "eq",
                "value": `${user.id}`,
                "logic": "and",
                "filters":[{
                    "field":"OdemeYapildiMi",
                    "operator":"eq",
                    "value":"false",
                    "logic":"and"
                }]
            }
        }


        Api.post("api/SiparisPendings/GetListDynamic?PageIndex=0&PageSize=100&BypassCache=true", reqData).then(res => {
            setBekleyenSiparis(res.data.items)
            setBekleyenSiparisLoading(false)
        }).catch(err => {
            message.error("Bekleyen siparişler getirilemedi").then(r => {})
            setBekleyenSiparisLoading(false)
        })

    }

    useEffect(() => {
        getSiparis()
        getBekleyenSiparis()
    }, []);

    return (<div className={"mt-2"}>


        {user && user.id > 0 && bekleyenSiparis && bekleyenSiparis.length > 0 &&
            <Card title={"Bekleyen Siparişler"}>
                <Table rowKey={"id"} loading={bekleyenSiparisLoading} dataSource={bekleyenSiparis} size={"small"} className={"h-100"} pagination={true}>
                    <Column title={"Sipariş Numarası"} width={200}  dataIndex={"siparisNo"}></Column>
                    <Column title={"Ödeme Durumu"}
                            width={200}
                            render={(_,record) => {
                                return(record.odemeYapildiMi ? <div style={{color:"green"}}>Ödendi</div> : <div style={{color:"orange"}}>Kuyrukta</div>)
                            }}
                            dataIndex={"odemeYapildiMi"}></Column>

                    <Column title={"Sipariş Toplam Tutar"} align={"right"} dataIndex={"siparisGenelToplam"}
                            render={(_,record) => {
                                return (<p style={{fontWeight:"bold"}}>{record.siparisToplamTutar.toFixed(2)}</p>)
                            }}></Column>
                </Table>
            </Card>
        }


        {user && user.id > 0 &&
            <Card title={"Siparişlerim"} className={"mt-1"}>
                <Table rowKey={"id"} loading={siparisLoading} dataSource={siparis} size={"small"} className={"h-100"} pagination={true}>
                    <Column title={"Sipariş Tarihi"} width={120} dataIndex={"siparisTarihi"}></Column>
                    <Column title={"Sipariş Numarası"} width={200}  dataIndex={"siparisNo"}></Column>
                    <Column title={"Durumu"}  dataIndex={"siparisDurum"} render={(_,record) => {return(<div style={{color:"green"}}>{record.siparisDurum}</div>)}}></Column>
                    <Column title={"Firma Adı"} width={200}  dataIndex={"cariAdi"}></Column>
                    <Column title={"Sipariş T. Tutar"} align={"right"} dataIndex={"siparisToplamTutar"}
                            render={(_,record) => {
                                return record.siparisToplamTutar.toFixed(2) + ""
                            }}></Column>
                    <Column title={"Sipariş KDV T. Tutar"} align={"right"} dataIndex={"siparisKdvTutar"}
                            render={(_,record) => {
                                return record.siparisKdvTutar.toFixed(2) + ""
                            }}></Column>

                    <Column title={"Sipariş Toplam Tutar"} align={"right"} dataIndex={"siparisGenelToplam"}
                            render={(_,record) => {
                                return (<p style={{fontWeight:"bold"}}>{record.siparisGenelToplam.toFixed(2)}</p>)
                            }}></Column>
                </Table>
            </Card>
        }


    </div>)
}

export default OrderManagementPage