import { useParams } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import Api from "../../services/Api";
import {Alert, Card, Checkbox, Input, message, Skeleton} from "antd";
import ProductListComponent from "./partials/ProductListComponent";
import ProductListFilterComponent from "./partials/ProductListFilterComponent";

const ProductSearchPageList = ()  =>  {
    const [products, setProducts] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filterIsStoktakiler, setFilterIsStoktakiler] = useState(false);

    let { searchText } = useParams();
    useEffect(() => {
        getProduct()
    }, [searchText])


    const getProduct = () => {

        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "StokAdi",
                "operator": "contains",
                "value": searchText,
                "logic": "or",
                "filters": [
                    {
                        "field": "StokBarkod",
                        "operator": "contains",
                        "value": searchText,
                        "logic": "or"
                    },
                    {
                        "field": "StokKodu",
                        "operator": "contains",
                        "value": searchText,
                        "logic": "or"
                    }
                ]
            }
        }


        Api.post("/api/Stoks/GetList/ByDynamic?PageIndex=0&PageSize=1000",requestDto).then(res => {
            setProducts(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Ürün bilgileri getirilirken hata oluştu.").then(err => {});
        })
    }

    return (
        <div className={"container"}>
            <div className={"section-products"}>


                <div className={"product-list-content"}>

                    <Skeleton className={"mt-1 "} active loading={loading}/>

                    {!loading &&
                        <div className={"product-list-header"}>
                            <Alert message={searchText.toLocaleUpperCase()+" aramasında "+products.length+" sonuç bulunmuştur."} type="warning" />
                            <div className={"mt-0-5"} style={{marginLeft:"10px", marginBottom:10}}>
                                <Checkbox onChange={(e) => setFilterIsStoktakiler(e.target.checked)}>Sadece Stoktakileri Göster</Checkbox>
                            </div>


                        </div>


                    }

                    <div className={"product-list"}>
                        <ProductListComponent filterProducts={products} filterIsStoktakiler={filterIsStoktakiler} loading={loading}></ProductListComponent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSearchPageList