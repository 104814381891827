import "../src/styles/Style.sass"
import 'swiper/css';
import 'swiper/css/pagination';
import {createBrowserRouter, createRoutesFromElements, defer, Route} from "react-router-dom";
import AuthLayout from "./pages/layout/AuthLayout";
import ProtectedLayout from "./pages/layout/ProtectedLayout";
import DashboardPage from "./pages/home/DashboardPage";
import LoginPage from "./pages/auth/LoginPage";
import ProductListPage from "./pages/product/ProductListPage";
import ProductDetail from "./pages/product/ProductDetail";
import BasketPage from "./pages/basket/BasketPage";
import OrderSuccessPage from "./pages/basket/OrderSuccessPage";
import AccountActivity from "./pages/report/AccountActivity";
import AccountMaturityAnalysis from "./pages/report/AccountMaturityAnalysis";
import AccountStatement from "./pages/report/AccountStatement";
import PaymentPage from "./pages/payment/PaymentPage";
import PaymentSuccessPage from "./pages/payment/result/PaymentSuccessPage";
import OrderManagementPage from "./pages/order/order-management/OrderManagementPage";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import HakkimizdaPage from "./pages/fiexedpage/HakkimizdaPage";
import IadeSartlariPage from "./pages/fiexedpage/IadeSartlariPage";
import MesafeliSatisSozlesmeiPage from "./pages/fiexedpage/MesafeliSatisSozlesmeiPage";
import SSSPage from "./pages/fiexedpage/SSSPage";
import IletisimPage from "./pages/fiexedpage/IletisimPage";
import ProductSearchPageList from "./pages/product/ProductSearchPageList";
import GizlilikSozlesmesiPage from "./pages/fiexedpage/GizlilikSozlesmesiPage";
import BankaBilgileriPage from "./pages/fiexedpage/BankaBilgileriPage";

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.localStorage.getItem("user");
            resolve(user);
        }, 1000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >

            <Route>
                <Route path={"/"} element={<LoginPage></LoginPage>}></Route>
                <Route path={"/login"} element={<LoginPage></LoginPage>}></Route>
            </Route>

            <Route path="/dashboard" element={<ProtectedLayout />}>
                <Route path="/dashboard" element={<DashboardPage />} />
            </Route>

            <Route path="/products" element={<ProtectedLayout />}>
                <Route path="/products" element={<ProductListPage />} />
                <Route path="/products/category/:categoryId" element={<ProductListPage />} />
                <Route path="/products/detail/:id" element={<ProductDetail />} />
                <Route path="/products/search/:searchText" element={<ProductSearchPageList />} />

            </Route>

            <Route path="/basket" element={<ProtectedLayout />}>
                <Route path="/basket" element={<BasketPage />} />
                <Route path="/basket/order/success/:id" element={<OrderSuccessPage />} />
            </Route>

            <Route path="/report" element={<ProtectedLayout />}>
                <Route path="/report/account-activity" element={<AccountActivity />} />
                <Route path="/report/account-maturity-analysis" element={<AccountMaturityAnalysis />} />
                <Route path="/report/account-statament" element={<AccountStatement />} />
            </Route>

            <Route path="/payment" element={<ProtectedLayout />}>
                <Route path="/payment" element={<PaymentPage />} />
            </Route>

            <Route path="/payment/card/success" element={<PaymentSuccessPage />} />
            <Route path="/payment/card/error" element={<PaymentPage />} />

            <Route path="/order" element={<ProtectedLayout />}>
                <Route path="/order/management" element={<OrderManagementPage />} />
            </Route>

            <Route path="/admin" element={<ProtectedLayout />}>
                <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
            </Route>

            <Route path="/sayfa" element={<ProtectedLayout />}>
                <Route path="/sayfa/hakkimizda" element={<HakkimizdaPage />} />
                <Route path="/sayfa/gizlilik-sozlesmesi" element={<GizlilikSozlesmesiPage />} />
                <Route path="/sayfa/iade-ve-iptal" element={<IadeSartlariPage />} />
                <Route path="/sayfa/banka-bilgileri" element={<BankaBilgileriPage/>} />
                <Route path="/sayfa/iletisim" element={<IletisimPage />} />
                <Route path="/sayfa/satis-sozlesmesi" element={<MesafeliSatisSozlesmeiPage />} />
                <Route path="/sayfa/sss" element={<SSSPage />} />
            </Route>


        </Route>
    )
)


