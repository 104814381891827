import {Await, useLoaderData, useOutlet} from "react-router-dom";
import {Suspense, useContext, useEffect} from "react";
import {Alert, ConfigProvider} from "antd";
import {AuthContextProvider} from "../../context/AuthContext";
import {BasketContextProvider} from "../../context/BasketContext";
import {LayoutContext} from "../../context/LayoutContext";
import trTR from "antd/locale/tr_TR";


const AuthLayout = () => {
    const outlet = useOutlet();
    const { userPromise } = useLoaderData();
    const { localStorageConfig } = useContext(LayoutContext)

    return(

        <Suspense>
            <Await
                resolve={userPromise}
                errorElement={<Alert severity="error">Something went wrong!</Alert>}
                children={(user) => (
                    <ConfigProvider
                        locale={trTR}
                        theme={{
                            token: {
                                colorPrimary: `${localStorageConfig?.theme['--primary-color']}`,
                            },
                        }}
                    >
                        <AuthContextProvider userData={user}>
                            <BasketContextProvider>
                                {outlet}
                            </BasketContextProvider>
                        </AuthContextProvider>
                    </ConfigProvider>

                )}
            />
    </Suspense>)
}

export default AuthLayout
