import AdminConfigComponent from "./partials/AdminConfigComponent";
import AdminCardTotalsComponent from "./partials/AdminCardTotalsComponent";
import AdminPlasiyerDashboardModalComponent from "./partials/Plasiyer/AdminPlasiyerDashboardModalComponent";
import {AuthContext} from "../../context/AuthContext";
import {useContext} from "react";

const AdminDashboardPage = () => {
    const {user} = useContext(AuthContext)

    return(<div className={"mt-2"}>

        <AdminCardTotalsComponent></AdminCardTotalsComponent>

        <AdminPlasiyerDashboardModalComponent></AdminPlasiyerDashboardModalComponent>

        {user.isAdmin &&
            <AdminConfigComponent></AdminConfigComponent>
        }

    </div>)
}

export default AdminDashboardPage;