import {Card} from "antd";

const AccountActivity = () => {

    return(<div className={"mt-2"}>
        <Card title={"Hesap Hareketleri"}>

        </Card>
    </div>)
}

export default AccountActivity;