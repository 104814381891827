import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import Api, {API_URL} from "../../../../services/Api";
import {message} from "antd";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const FooterBrandsComponent = () => {
    const [slider, setSlider] = useState([]);

    const getData = () => {
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "Category",
                "operator": "eq",
                "value": "slider",
                "logic": "and",
                "filters": [
                    {
                        "field": "Code",
                        "operator": "eq",
                        "value": "FOOTER MARKALAR",
                        "logic": "and"
                    }
                ]
            }
        }

        Api.post("/api/Parametres/GetListDynamic?PageIndex=0&PageSize=100",requestDto).then(res => {
            setSlider(res.data.items)
        }).catch(err => {
            message.error("Slider Getirilirken Hata oluştu").then(err => {});
        })
    }


    useEffect(() => {
        getData()
    }, []);


    return(
        <Swiper
            slidesPerView={1}
            spaceBetween={0}

            pagination={{
                clickable: true,
            }}
            modules={[Autoplay]}
            autoplay={{
                delay: 3000}
            }
            breakpoints={{
                350: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
            }}
            className="container"
        >

            {slider.map((item, index) => {
                return(
                    <SwiperSlide key={index} className={"slider"}>
                        <Link to={item.valueType}>
                            <img src={API_URL + "uploads/" + item.value}
                                 alt={"Slider 1"}></img>
                        </Link>

                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default FooterBrandsComponent