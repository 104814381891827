import {useOutlet} from "react-router-dom";

import HeaderLayout from "./HeaderLayout";
import {useContext} from "react";
import {LayoutContext} from "../../context/LayoutContext";
import FooterLayout from "./FooterLayout";

const ProtectedLayout = () => {
    const outlet = useOutlet();
    const {configJS} = useContext(LayoutContext)

    if(outlet == null){
        return(<div>Görüntülenecek Sayfa Yok</div>)
    }

    // if (!user ) {
    //     return <Navigate to="/login"/>;
    // }

    return(
        <div className={"app"}>

            <HeaderLayout></HeaderLayout>
            <div className={"app-container"}>
                <div className={"container"}>
                    {outlet}
                </div>
            </div>
            <FooterLayout></FooterLayout>

        </div>
    )
}

export default ProtectedLayout