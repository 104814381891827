import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faMinus, faPhone, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useContext, useState} from "react";
import {LayoutContext} from "../../context/LayoutContext";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";
import {faFacebook, faInstagram, faXTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import FooterBrandsComponent from "./partials/footer/FooterBrandsComponent";

const FooterLayout = () => {
    const {configJS} = useContext(LayoutContext)
    const [isFooterContentActive, setIsFooterContentActive] = useState({name:"", isActive:false});

    const openFooterContent = (name) => {
        //açıksa kapat kapalıysa aç
        setIsFooterContentActive({name: name, isActive: !isFooterContentActive.isActive})
    }

    return (
        <div className={"footer box-shadow"}>

            <div className={"footer-logo container"}>
                <div className={"footer-item"}>
                    <img src={configJS?.company?.logo_url} alt={"Logo"}></img>
                </div>
                <div className={"footer-item"}>
                    <div className={"footer-item-title"}><FontAwesomeIcon icon={faInstagram}/></div>
                    <br/>
                    <div className={"footer-item-title"}><FontAwesomeIcon icon={faXTwitter}/></div>
                    <br/>
                    <div className={"footer-item-title"}><FontAwesomeIcon icon={faFacebook}/></div>
                    <br/>
                    <div className={"footer-item-title"}><FontAwesomeIcon icon={faYoutube} /></div>

                </div>
            </div>

            <div className={"footer-brands"}>
                <FooterBrandsComponent></FooterBrandsComponent>
            </div>

            <div className={"footer-content container"}>
                <div className={"footer-item"}>
                    <div className={"footer-item-title"} onClick={() => openFooterContent("uyelik")}>
                        <div className={"title"}>ÜYELİK</div>
                        <div className={"mobile-plus"}>{isFooterContentActive.name === "uyelik" && isFooterContentActive.isActive?<FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </div>
                    </div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "uyelik" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/login"}>Çıkış Yapın</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "uyelik" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/order/management"}>Siparişlerim</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "uyelik" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/report/account-statament"}>Hesap Ekstresi</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "uyelik" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/report/account-maturity-analysis"}>Vade Analiz Raporu</Link></div>
                </div>

                <div className={"footer-item"}>
                    <div className={"footer-item-title"} onClick={(e) => openFooterContent("kurumsal")}>
                        <div className={"title"}>KURUMSAL</div>
                        <div className={"mobile-plus"}>{isFooterContentActive.name === "kurumsal" && isFooterContentActive.isActive?<FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </div>
                    </div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "kurumsal" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/sayfa/iletisim"}>İletişim</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "kurumsal" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/sayfa/iletisim"}>İletişim Formu</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "kurumsal" && isFooterContentActive.isActive? 'active':''}`}><Link to={"#"}>Havale Bildirim Formu</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "kurumsal" && isFooterContentActive.isActive? 'active':''}`}><Link to={"#"}>Banka Hesap Numaraları</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "kurumsal" && isFooterContentActive.isActive? 'active':''}`}></div>
                </div>

                <div className={"footer-item"}>
                    <div className={"footer-item-title"} onClick={(e) => openFooterContent("alisveris")}>
                        <div className={"title"}>ALIŞVERİŞ</div>
                        <div
                            className={"mobile-plus"}>{isFooterContentActive.name === "alisveris" && isFooterContentActive.isActive ?
                            <FontAwesomeIcon icon={faMinus}/> : <FontAwesomeIcon icon={faPlus}/>} </div>
                    </div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "alisveris" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/sayfa/satis-sozlesmesi"}>Mesafeli Satış Sözleşmesi</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "alisveris" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/sayfa/gizlilik-sozlesmesi"}>Gizlilik ve Güvenlik</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "alisveris" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/sayfa/iade-ve-iptal"}>İptal İade Koşullari</Link></div>
                    <div className={`footer-item-link ${isFooterContentActive.name === "alisveris" && isFooterContentActive.isActive? 'active':''}`}><Link to={"/sayfa/gizlilik-sozlesmesi"}>Kişisel Veriler Politikası</Link></div>
                </div>

                <div className={"footer-item"}>
                    <div className={"footer-item-title"} onClick={(e) => openFooterContent("iletisim")}>
                        <div className={"title"}>İLETİŞİM</div>
                        <div
                            className={"mobile-plus"}>{isFooterContentActive.name === "alisveris" && isFooterContentActive.isActive ?
                            <FontAwesomeIcon icon={faMinus}/> : <FontAwesomeIcon icon={faPlus}/>} </div>
                    </div>

                    {configJS?.company?.footer_desc &&
                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            {configJS?.company?.footer_desc}
                        </div>
                    }


                    {configJS?.company?.name &&

                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            <p className={"font-weight-bold"}>Firma Ünvanı:</p> {configJS?.company?.name}
                        </div>
                    }


                    {configJS?.company?.mail &&
                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            <p className={"font-weight-bold"}>E-posta Adresi:</p> {configJS?.company?.mail}
                        </div>
                    }

                    {configJS?.company?.address &&
                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            <p className={"font-weight-bold"}>Adres:</p> {configJS?.company?.address}
                        </div>
                    }

                    {configJS?.company?.whatsapp &&
                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            <p className={"font-weight-bold"}>WhatsApp:</p> <Link to={`https://wa.me/${configJS?.company?.whatsapp}`}>{configJS?.company?.whatsapp}</Link>
                        </div>
                    }

                    {configJS?.company?.phone &&
                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            <p className={"font-weight-bold"}>Telefon:</p> <Link to={`tel:${configJS?.company?.phone}`}>{configJS?.company?.phone}</Link>
                        </div>
                    }


                    {configJS?.company?.phone2 &&
                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            <p className={"font-weight-bold"}>Telefon2:</p> <Link to={`tel:${configJS?.company?.phone2}`}>{configJS?.company?.phone2}</Link>
                        </div>
                    }

                    {configJS?.company?.phone3 &&
                        <div
                            className={`footer-item-link ${isFooterContentActive.name === "iletisim" && isFooterContentActive.isActive ? 'active' : ''}`}>
                            <p className={"font-weight-bold"}>Telefon3:</p> <Link to={`tel:${configJS?.company?.phone3}`}>{configJS?.company?.phone3}</Link>
                        </div>
                    }

                </div>
            </div>

            <div className={"footer-powered"}>
                <div className={"container"}>
                    <div className={"footer-item"}>
                        <div className={"footer-powered-content-text"}>
                        Copyright © {dayjs(new Date()).format("YYYY")} {configJS.title} Tüm Hakları Saklıdır.
                        </div>
                    </div>

                    <div className={"footer-item"}>
                    Kredi kartı bilgileriniz 256bit SSL sertifikası ile korunmaktadır.
                    </div>
                </div>

            </div>

            <div className={"footer-credit-card container"}>
                <div className={"footer-item"}>
                    <img
                        src={"https://ideacdn.net/idea/kp/24/themes/selftpl_645b7db52548f/assets/uploads/footer_ssl_image.png?revision=7.2.8.4-1-1722451068"}
                        alt={"visa"}/>

                </div>
            </div>

        </div>
    )
}

export default FooterLayout