import {Button, Col, Form, Input, message, Row, Select, Switch} from "antd";
import Api from "../../../../services/Api";
import {useEffect, useState} from "react";

const AdminUserDetailPage = ({data, onClose}) => {
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(true)

    const getUser = () => {
        Api.get("/api/Users/"+data.selectedUserId).then(res => {
            form.setFieldsValue(res.data)
            setLoading(false)
        }).catch(err => {
            message.error(err.response.data.Detail)
        })
    }

    useEffect(() => {
        if(data.selectedUserId !== null)
            getUser()
        else
            setLoading(false)
    }, []);

    const onFinish = (values) => {
        if(values.id === undefined || values.id === null)
        {
            const user = {email:values.email, password:values.password}
            Api.post("/api/auth/register", values).then(res => {
                message.success("Kullanıcı başarılı bir şekilde eklendi")
                onClose()
            }).catch(err => {
                message.error(err.response.data.Detail)
            })
        }
        else
        {
            // const user = {id:values.id, email:values.email, password:values.password, firstName:"sistem", lastName:"sistem"}

           Api.put("/api/Users/", values).then(res => {
                message.success("Kullanıcı başarılı bir şekilde güncellendi")
               onClose()
           }).catch(err => {
                message.error(err.response.data.Detail)
           })
        }

    }
    const onFinishFailed = () => {
        message.error("Formda eksik alanlar var")
    }

    return(<div>

        {!loading &&

            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{id: data.selectedUserId}}
                labelCol={{
                    span: 8,
                }}

                labelAlign={"left"}
                colon={false}
                onFinishFailed={onFinishFailed}
            >

                <Row gutter={[36, 0]}>

                    <Form.Item label="User ID" name="id" hidden={true}>
                        <Input/>
                    </Form.Item>

                    <Col span={24}>
                        <Form.Item label="Adı" name="firstName" rules={[{ required: true },]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Soyadı" name="lastName" rules={[{ required: true },]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="E-Posta Adresi" name="email" rules={[{ required: true },]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Kullanıcı Aktif Mi?" name="status" rules={[{ required: true },]}>
                            <Select>
                                <Select.Option value={true}>Evet</Select.Option>
                                <Select.Option value={false}>Hayır</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Kullanıcı Plasiyer Mi?" name="isPlasiyer" rules={[{ required: true },]}>
                            <Select>
                                <Select.Option value={true}>Evet</Select.Option>
                                <Select.Option value={false}>Hayır</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Plasiyer ise Plasiyer Kodu" name="plasiyerCode">
                           <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Kullanıcı Admin Düzeyinde Yetkili Mi?" name="isAdmin" rules={[{ required: true },]}>
                            <Select>
                                <Select.Option value={true}>Evet</Select.Option>
                                <Select.Option value={false}>Hayır</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Şifre" name="password">
                            <Input.Password/>
                        </Form.Item>
                    </Col>



                    <Col span={24} style={{textAlign:"right",marginTop:"1rem"}}>
                        <Button htmlType="submit" type={"primary"}>Kaydet</Button>
                    </Col>
                </Row>
            </Form>

        }

    </div>)
}

export default AdminUserDetailPage;