import {useEffect, useState} from "react";
import Api from "../../services/Api";
import {Card, message} from "antd";
import parse from "html-react-parser";

const MesafeliSatisSozlesmeiPage = () => {
    const [data, setData] = useState([]);

    const getData = () => {
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "Code",
                "operator": "eq",
                "value": "satis-sozlesmesi",
                "logic": "and"
            }
        }

        Api.post("/api/Parametres/GetListDynamic?PageIndex=0&PageSize=100",requestDto).then(res => {
            setData(res.data.items[0]);
        }).catch(err => {
            message.error("Sayfa getirilirken hata oluştu").then(err => {});
        })

    }

    useEffect(() => {
        getData();
    }, []);


    return(<div className={"mt-1"}>
        <Card title={"Satış Sözleşmesi"}>
            {data && parse("<div>"+data.value+"</div>")}
        </Card>
    </div>)
}

export default MesafeliSatisSozlesmeiPage