import {useContext, useEffect, useState} from "react";
import {message, Modal} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {BasketContext} from "../../context/BasketContext";
import Api from "../../services/Api";
import {AuthContext} from "../../context/AuthContext";

const BasketQuantityModal = ({ isOpen, product, reqCount, onClose}) => {
    const {user} = useContext(AuthContext);

    const ModalContent = ({product, reqCount, onClose }) => {

        const [setLoading, loading] = useState(false);
        const [stockControl, setStockControl] = useState();

        const {addProductWithCount } = useContext(BasketContext);
        const [count, setCount] = useState(reqCount);

        const modalBasketIncreaseProduct = () => {
            setCount(count+1);
        }

        const modalBasketDecreaseProduct = () => {
            if(count === 1) return;

            setCount(count-1);
        }

        const modalBasketAddBasket = () => {

            if(user?.isPlasiyer)
            {
                message.error("Plasiyerler sepete ürün ekleyemez.").then(r => {})
                return;
            }

            if(count > stockControl?.stokMiktari){
                message.error("Stokta yeterli ürün bulunmamaktadır.").then(r => {})
                return;
            }

            addProductWithCount(product, count);

            onClose();
            setCount(1);

            message.success(count+" Ürün sepete eklendi").then(r => {})
        }

        const modalChangeQuantity = (e) => {
            setCount(e.target.valueAsNumber);
        }

        useEffect(() => {
            message.info("Anlık Stok bilgisi alınıyor")
            Api.get("/api/Stoks/GetByStok?StokKey="+product?.stokKey).then(res => {
              setStockControl(res.data)
            }).catch(err => {
                message.error("Stok bilgisi alınamadı")
            })
        }, [product])

        return (

            <div className={"basket-modal"}>
                <div className={"product-image w-100"}>
                    <img src={product?.stokResim} alt={product?.stokAdi}></img>
                </div>
                <div className={"product-detail"}>
                    {stockControl?.stokMiktari > 0 &&

                        <>
                            <div className={"text-align-center"}>En fazla sipariş edilecek
                                miktar: {stockControl?.stokMiktari} {product?.stokBirim}</div>

                            <div className={"product-quantity"}>
                                <div className={"negative button"} onClick={() => {
                                    modalBasketDecreaseProduct()
                                }}>
                                    <FontAwesomeIcon icon={faMinus}/>
                                </div>
                                <input type={"number"}
                                       value={count}
                                       onChange={(e) => modalChangeQuantity(e)}
                                ></input>
                                <div className={"plus button"} onClick={() => {
                                    modalBasketIncreaseProduct()
                                }}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </div>
                            </div>

                            <div onClick={() => modalBasketAddBasket()} className={"add-basket"}>
                                <div className={"icon"}><FontAwesomeIcon icon={faCartPlus}/></div>
                                <div className={"label"}>Sepete Ekle</div>
                            </div>
                        </>


                    }

                    {stockControl?.stokMiktari <= 0 &&
                        <div className={"add-basket"} style={{backgroundColor:'gray'}}>
                            <div className={"icon"}><FontAwesomeIcon icon={faCartPlus}/></div>
                            <div className={"label"}>Stok Yok</div>
                        </div>
                    }

                </div>
            </div>


        )
    }

    return (
        <Modal open={isOpen}
               width={500}
               centered={true}
               destroyOnClose={true}
               onCancel={() => onClose()} footer={[]} title={product?.stokAdi}>
            <ModalContent isOpen={isOpen} reqCount={reqCount} onClose={onClose} product={product}/>
        </Modal>
    )
};

export default BasketQuantityModal;