import {createContext, useContext, useEffect, useState} from "react";
import {message} from "antd";
import {AuthContext} from "./AuthContext";

const BasketContext = createContext();

const BasketContextProvider = ({children}) => {
    const [products, setProducts] = useState([]);
    const {user} = useContext(AuthContext)


    // useEffect(() => {
    //   getBasketData();
    //
    // }, []);

    useEffect(() => {
        if(user != null && user.id != null){
            getBasketData()
        }
    }, [user]);


    const getBasketData = () => {
        const basket = JSON.parse(localStorage.getItem("basket-"+user.id));
        if(basket != null){
            setProducts(basket);
        }
        else{
            setProducts([]);
        }
    }

    useEffect(() => {
        if(user != null && user.id != null){
            localStorage.setItem("basket-"+user.id, JSON.stringify(products));
        }
    }, [products]);



    const addProduct = (product) => {
        try {

            if(products.filter(item => item.id === product.id).length >= product.stokMiktar){
                message.error("Stok miktarı yetersiz").then(r => {}); return false;
            }

            //buraa kdv hesaplayıp eklemem gerekiyor


            if(product.stokKdvDurumu === "H"){
                setProducts([...products,
                    {...product,
                        stokKdvToplam: ((product.stokKdv/100)*product.stokFiyat)+product.stokFiyat,
                        stokKdvTutar: ((product.stokKdv/100)*product.stokFiyat)},
                ])
            }

            else{
                setProducts([...products,
                    {...product,
                        stokKdvToplam: product.stokFiyat,
                        stokKdvTutar: 0},
                ])
            }

            message.success("Ürün sepete eklendi").then(r => {})

            return true;

        } catch (e) {
            message.error("Bir hata oluştu").then(r => {})
        }
    }

    const addProductWithCount = (product, count) => {

        let productArray = []

        for (let i = 0; i < count; i++) {
            if(product.stokKdvDurumu === "H") {
                productArray.push({...product,
                    stokKdvToplam: ((product.stokKdv/100)*product.stokFiyat)+product.stokFiyat,
                    stokKdvTutar: ((product.stokKdv/100)*product.stokFiyat)})
            }
            else{
                productArray.push({...product,
                    stokKdvToplam: product.stokFiyat,
                    stokKdvTutar: 0})
            }

        }

        setProducts([...products, ...productArray])
    }

    const decreaseProduct = (product) => {
        const selectedProduct = products.filter(item => item.id === product.id)
        const selectedProductCount = selectedProduct.length
        const cleanRemoveProduct = products.filter(item => item.id !== product.id)

        for (let i = 0; i < (selectedProductCount-1); i++) {
            cleanRemoveProduct.push(selectedProduct[i])
        }

        setProducts(cleanRemoveProduct)

        message.info("Ürün sepetten çıkarıldı").then(r => {})
    }

    const removeProduct = (product) => {
        setProducts(products.filter(p => p.id !== product.id));
        message.info("Ürün sepetten çıkarıldı").then(r => {})
    }

    const productRowTotal = (product) => {
        const selectedProduct = product.filter(item => item.id === product.id)
        return selectedProduct.length
    }

    const productBasketRemoveAll = () => {
        setProducts([])
        return true;
    }



    return(
        <BasketContext.Provider
            value={{
                products,
                getBasketData,
                addProduct,
                removeProduct,
                decreaseProduct,
                addProductWithCount,
                productBasketRemoveAll
            }}
        >
            {children}
        </BasketContext.Provider>
    )
}

export {BasketContextProvider, BasketContext}