import {useEffect, useState} from "react";
import Api, {API_URL} from "../../../../services/Api";
import {Button, Card, Col, Form, Image, Input, message, Modal, Row, Table} from "antd";
import {FormOutlined} from "@ant-design/icons";
import SliderDetail from "./partials/SliderDetailComponent";

const AdminSliderListPage = () => {
    const [data, setData] = useState([]);
    const [sliderDetailModal, setSliderDetailModal] = useState({isActive:false, selected:[]})

    const getData = () => {
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "Category",
                "operator": "eq",
                "value": "slider",
                "logic": "and"
            }
        }

        Api.post("/api/Parametres/GetListDynamic?PageIndex=0&PageSize=100",requestDto).then(res => {
            const grouped = res.data.items.reduce((acc, item) => {
                // Eğer henüz acc[item.code] tanımlı değilse, acc array'ine boş bir dizi ekleyin.
                if(!acc[item.code]) {
                    acc[item.code] = [];
                }
                acc[item.code].push(item);
                return acc;
            }, []);

            // acc objesinden array'e dönüştürme
            const resultArray = Object.keys(grouped).map((key, index) => {
                return {
                    index: index,
                    code: key,
                    items: grouped[key]
                };
            });
            setData(resultArray);
        }).catch(err => {
            message.error("Sayfa getirilirken hata oluştu").then(err => {});
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const modalOpen = (items) => {
        setSliderDetailModal({isActive:true, selected:items})
    }

    return(
        <>
            <Modal
                title={"Slider Ekle veya Düzenle"}
                open={sliderDetailModal.isActive}
                centered={true}
                width={1000}
                footer={[]}
                destroyOnClose={true}
                onCancel={() => {setSliderDetailModal({isActive: false, selected: []})}}
            >
                <SliderDetail items={sliderDetailModal.selected} refreshData={() => getData()} modalClose={() => {setSliderDetailModal({isActive: false, selected: []})}}></SliderDetail>
            </Modal>
            <Table dataSource={data}  rowKey={"index"}>
                <Table.Column title={""} width={50} render={(_,record) => {
                    return(
                        <Button onClick={(e) => modalOpen(record.items)} type={"primary"} size={"small"} icon={<FormOutlined />}></Button>

                    )
                }}></Table.Column>
                <Table.Column className={"font-weight-bold"} dataIndex={"code"}></Table.Column>

            </Table>
        </>
    )
}


export default AdminSliderListPage