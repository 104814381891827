import {Button, Checkbox, Form, Input, message, Switch} from "antd";
import {useContext, useState} from "react";
import Api from "../../services/Api";
import {AuthContext} from "../../context/AuthContext";

const LoginPage = () => {
    const [loading, setLoading] = useState();
    const {login, adminLogin, user} = useContext(AuthContext);

    const onFinish = values => {
        if(values.remember){
            firmaGirisi(values)
        }else{
            plasiyerGirisi(values)
        }

    };

    const firmaGirisi = (values) => {
        setLoading(true);

        Api.post("/api/CariAuth", {email:values.username, password:values.password}).then(res => {
            if(res.data != null){
                setLoading(false)
                login(res.data)
            }
        }).catch(err => {
            message.error(err.response.data.Detail).then(r=> {})
            setLoading(false)
        })
    }

    const plasiyerGirisi = (values) => {
        setLoading(true);

        Api.post("/api/Auth/Login", {email:values.username, password:values.password}).then(res => {
            if(res.data != null){
                setLoading(false)
                adminLogin(res.data)
                localStorage.setItem("admin",JSON.stringify({email:values.username, password:values.password}))
            }
        }).catch(err => {
            message.error(err.response.data.Detail).then(r=> {})
            setLoading(false)
        })
    }

    const onFinishFailed = errorInfo => {
        message.error("Başarısız oldu")
    };

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img
                        src="https://st2.depositphotos.com/7865540/10697/i/450/depositphotos_106977982-stock-photo-businessman-pointing-on-digital-tablet.jpg"
                        alt="Login"/>
                </div>
                <Form
                    name="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <p className="form-title">Hoş Geldiniz</p>
                    <p>Lütfen bilgileri doldurunuz</p>
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Lütfen e-posta adresinizi yazın'}]}
                    >
                        <Input
                            placeholder="E-posta adresiniz"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Lütfen şifrenizi yazın'}]}
                    >
                        <Input.Password
                            placeholder="Şifreniz"
                        />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Switch defaultChecked={false} checkedChildren="Firma Girişi" unCheckedChildren="Plasiyer Girişi" />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                            GİRİŞ YAPIN
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginPage;