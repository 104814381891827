import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBasketShopping, faCircleCheck, faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation} from "react-router-dom";

const OrderSuccessPage = () => {
    const { state } = useLocation();


    return (
        <div className={"basket-card mt-2 box-shadow"}>
            <div className={"card-title"}>
                <div className={"icon"}>
                    <FontAwesomeIcon icon={faBasketShopping}/>
                </div>
                <div className={"title"}>
                    İşlem Başarılı
                </div>
            </div>
            <div className={"basket-card-content"}>
                 
                <div className={"order-success"}>
                    <div className={"icon"}><FontAwesomeIcon icon={faCircleCheck}/></div>
                    <div className={"text"}>Siparişiniz başarılı bir şekilde alınmıştır</div>

                    <div className={"order-detail mt-2"}>
                        <div className={"label"}>Sipariş Numarası:</div>
                        <div className={"value"}>{state.order.key} - {state.order.siparisNo}</div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default OrderSuccessPage;