import {Card, Col, message, Row} from "antd";
import {useLocation, useParams} from "react-router-dom";
import Api from "../../../services/Api";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBasketShopping, faCircleCheck} from "@fortawesome/free-solid-svg-icons";

const PaymentSuccessPage = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const resOrderId = queryParams.get('OrderId');
    const [loading, setLoading] = useState(true);

    if(resOrderId === null || resOrderId === undefined)
    {
        return <div className={"font-weight-bold text-align-center p-3"}>
            Ödeme bilgileri alınamadı
        </div>
    }

    //localStorage'den sipariş numarası alınabilir
    let order = JSON.parse(localStorage.getItem("order"))

    if(order === null || order === undefined)
    {
        if(resOrderId !== order?.siparisNo){
            return <div className={"font-weight-bold text-align-center p-3"}>
                Sipariş numarası hatalı veya doğru değil ya da işleme alınmış
            </div>
        }

        return <div className={"font-weight-bold text-align-center p-3"}>
            Sipariş bilgileri alınamadı
        </div>
    }

    const sendSiparisPending = () => {

        const data = {
            siparisNo: order.siparisNo,
            cariKey:0,
            cariId: order.cariId,
            siparisJson: JSON.stringify(order),
            siparisToplamTutar:order.genelToplam,
        }

        Api.post("api/SiparisPendings", data).then(res => {
            localStorage.removeItem("order")
        }).catch(err => {
            message.error(err.response.data.Detail).then(r => {})
        })
    }

    useEffect(() => {
        sendSiparisPending()
    }, []);

    return(<div className={"basket-card mt-2 box-shadow"}>
        <div className={"card-title"}>
            <div className={"icon"}>
                <FontAwesomeIcon icon={faBasketShopping}/>
            </div>
            <div className={"title"}>
                İşlem Başarılı
            </div>
        </div>
        <div className={"basket-card-content"}>
            <div className={"order-success"}>
                <div className={"icon"}><FontAwesomeIcon icon={faCircleCheck}/></div>
                <div className={"text"}>Siparişiniz başarılı bir şekilde alınmıştır</div>

                <div className={"order-detail mt-2"}>
                    <div className={"label"}>Sipariş Numarası:</div>
                    <div className={"value"}>{resOrderId}</div>
                </div>
            </div>

        </div>
    </div>)
}

export default PaymentSuccessPage