import {Button, Col, Form, Input, InputNumber, message, Row, Table} from "antd";
import Api from "../../../../services/Api";
import {useEffect, useState} from "react";

const AdminDovizPage = () => {
    const [data, setData] = useState([]);

    const getData = () => {
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "Category",
                "operator": "eq",
                "value": "exchange",
                "logic": "and"
            }
        }

        Api.post("/api/Parametres/GetListDynamic?PageIndex=0&PageSize=10",requestDto).then(res => {
            setData(res.data.items);
        }).catch(err => {
            message.error("Sayfa getirilirken hata oluştu").then(err => {});
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const updateData = (record) => {
        Api.put("/api/Parametres/",record).then(res => {
            message.success("Güncelleme başarılı").then(r => {});
        }).catch(err => {
            message.error("Güncelleme sırasında hata oluştu").then(r => {});
        })
    }

    return(
        <>
            <Row gutter={[10,10]}>
                <Col span={24}>

                    <Table dataSource={data}>
                        <Table.Column className={"font-weight-bold"} title={"Kodu"} dataIndex={"code"}></Table.Column>

                        <Table.Column className={"font-weight-bold"} title={"Değeri"}
                        render={(text, record) => (
                            <Form>
                                <Form.Item>
                                    <InputNumber onChange={(e) => {
                                        setData(data.map(item => {
                                            if(item.id === record.id){
                                                item.value = e.toString();
                                            }
                                            return item;
                                        }))
                                    }} min={0} defaultValue={record.value}></InputNumber>
                                </Form.Item>
                            </Form>
                        )}></Table.Column>

                        <Table.Column className={"font-weight-bold"} title={"İşlem"}
                        render={(text, record) => (
                            <Button onClick={(e) => updateData(record)} type={"primary"}>Güncelle</Button>
                        )}></Table.Column>

                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default AdminDovizPage