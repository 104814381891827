import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";
import {useEffect, useState} from "react";
import Api from "../../../services/Api";
import {message} from "antd";
import BasketQuantityModal from "../../basket/BasketQuantityModal";
import flexCurrency from "../../../utils/FlexCurrency";
import ExChangeCurrency from "../../../utils/ExChangeCurrency";

const DashboardBestSellersComponent = () => {
    const [data,setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [basketQuantityModal, setBasketQuantityModal] = useState({isActive: false, product: null, count: 1});

    const getData = () => {
        Api.get("api/Stoks/GetListBestSeller?PageIndex=0&PageSize=100").then(res => {
            setData(res.data.items)
        }).catch(err => {
            message.error("En çok satılan ürünler getirilemedi").then(r => {})
        })
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <>
            { data && data.length > 0 &&
                <div className={"card-list mt-1"}>
                    <BasketQuantityModal
                        isOpen={basketQuantityModal.isActive}
                        product={basketQuantityModal.product}
                        reqCount={basketQuantityModal.count}
                        onClose={() => setBasketQuantityModal({isActive: false, product: null, count: 1})}
                    >
                    </BasketQuantityModal>

                    <div className={"card-list-label"}>
                        <div className={"label"}>EN ÇOK SATILAN ÜRÜNLER</div>
                        <Link to={"/products"} className={"more"}>Tüm Ürünler »</Link>
                    </div>
                    <div className={"card-content"}>
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={25}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay]}
                            autoplay={true}
                            breakpoints={{
                                350: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                            }}
                            className="mySwiper"
                        >

                            {data.map((product) => (
                                <SwiperSlide className={"card-item"} key={product.id}>
                                    <div className={"card-item-content"}>
                                        <div className={"product-image"}>
                                            <Link to={"/products/detail/"+product.id}>
                                                <img src={product.stokResim} alt={product.stokAdi}
                                                     onError={(e) => {
                                                         e.target.onerror = null;
                                                         e.target.src = "https://vera.com.tr/wp-content/uploads/2019/11/urun-resmi-yok.png.webp"
                                                     }}
                                                ></img>
                                            </Link>
                                        </div>
                                        <div className={"product-content"}>
                                            <div className={"product-title"}>{product.stokAdi}</div>
                                            <div className={"product-info"}>
                                                <div
                                                    className={"product-price"}>{ExChangeCurrency(product)}</div>
                                                <div onClick={() => {
                                                    setBasketQuantityModal({isActive: true, product: product, count: 1})
                                                }} className={"product-add-basket"}>Sepete Ekle
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}

                        </Swiper>
                    </div>
                </div>
            }
        </>
    )
}

export default DashboardBestSellersComponent;