import {createContext, useEffect, useState} from "react";
import {message} from "antd";
import Api from "../services/Api";

const LayoutContext = createContext();

const LayoutContextProvider = ({children}) => {
    const [configJS, setConfigJS] = useState({})
    const [currency, setCurrency] = useState("TL");


    useEffect(() => {
        getConfigApiJS()
    }, []);

    const requriedConfigJs = () => {
        // çerezden getir ve yoksa api den getir

        if(localStorage.getItem("config")){
            setConfigJS(JSON.parse(localStorage.getItem("config")))
        }
        else{
            getConfigApiJS()
        }

        return configJS;
    }

    const localStorageConfig = JSON.parse(localStorage.getItem("config"));

    const getConfigApiJS = () => {
        Api.get("/api/parametres/config")
            .then(res => {

                setConfigJS(res.data);
                localStorage.setItem("config", JSON.stringify(res.data))

                const root = document.documentElement;
                root.style.setProperty('--primary-color', res.data?.theme['--primary-color']);
                root.style.setProperty('--secondary-color', res.data?.theme['--secondary-color']);
                root.style.setProperty('--dark-color', res.data?.theme['--dark-color']);
                root.style.setProperty('--info-color', res.data?.theme['--info-color']);
                root.style.setProperty('--success-color', res.data?.theme['--success-color']);
                root.style.setProperty('--warning-color', res.data?.theme['--warning-color']);
                root.style.setProperty('--danger-color', res.data?.theme['--danger-color']);
                root.style.setProperty('--light-color', res.data?.theme['--light-color']);
                root.style.setProperty('--white-color', res.data?.theme['--white-color']);
                root.style.setProperty('--white-secondory-color', res.data?.theme['--white-secondory-color']);
                root.style.setProperty('--hover-color', res.data?.theme['--hover-color']);
                root.style.setProperty('--shadow-color', res.data?.theme['--shadow-color']);
                root.style.setProperty('--app-background-color', res.data?.theme['--app-background-color']);
                root.style.setProperty('--gray-color', res.data?.theme['--gray-color']);
                root.style.setProperty('--hover-primary-color', res.data?.theme['--hover-primary-color']);

            })
            .catch(e => {
                message.error("Config dosyası yüklenemedi");
            })
    }

    return(
        <LayoutContext.Provider
            value={{
                configJS,
                setConfigJS,
                getConfigApiJS,
                requriedConfigJs,
                localStorageConfig,
                currency,
                setCurrency
            }}
        >
            {children}
        </LayoutContext.Provider>
    )
}
export {LayoutContextProvider, LayoutContext}
