import {useState} from "react";
import {Button, Col, Modal, Row} from "antd";
import AdminPlasiyerDashboardComponent from "./AdminPlasiyerDashboardComponent";

const AdminPlasiyerDashboardModalComponent = () => {
    const [modal, setModal] = useState({isOpen:false});

    return (
       <>
           <Modal open={modal.isOpen}
                  className={"custom-modal"}
                  destroyOnClose={true}
                  width={"95%"}
                  footer={[]}
                  closable={false}
                  mask={true}
                  onCancel={() => setModal({isOpen: false})}>
               <AdminPlasiyerDashboardComponent></AdminPlasiyerDashboardComponent>
           </Modal>

           <Row gutter={[10,10]}>
               <Col span={24}>
                   <Button type={"dashed"} onClick={() => setModal({isOpen: true})} className={"w-100 mt-1 font-weight-bold"}>Plasiyer Moduna Geç</Button>
               </Col>
           </Row>
       </>


    )
}

export default AdminPlasiyerDashboardModalComponent;