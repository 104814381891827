import flexCurrency from "./FlexCurrency";
import React, {useContext} from "react";
import {LayoutContext} from "../context/LayoutContext";

const ExChangeCurrency = (product) => {
    const {currency} = useContext(LayoutContext)

    if (!product?.stokExchangeFiyat) return <>{flexCurrency(product.stokFiyat)} {product.stokParaBirimi}</>

    const fiyat = product?.stokExchangeFiyat?.find(item => item.paraBirimi === currency)?.fiyat || 999999;
    return <>{flexCurrency(fiyat)} {currency}</>
}

export default ExChangeCurrency