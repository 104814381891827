import {Button, Card, Col, Drawer, message, Modal, Popconfirm, Row} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBasketShopping, faMinus, faPlus, faReceipt, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import {BasketContext} from "../../context/BasketContext";
import {useContext, useEffect, useState} from "react";
import Api from "../../services/Api";
import {AuthContext} from "../../context/AuthContext";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import PaymentPage from "../payment/PaymentPage";
import {LayoutContext} from "../../context/LayoutContext";
import flexCurrency from "../../utils/FlexCurrency";

const BasketPage = () => {
    const {products, addProduct, decreaseProduct, removeProduct, productBasketRemoveAll} = useContext(BasketContext)
    const [data,setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const {user} = useContext(AuthContext)
    const [orderConfirmModal, orderContextHolder] = Modal.useModal();
    const navigate = useNavigate();
    const [creditCardModal, setCreditCardModal] = useState(false);
    const {configJS} = useContext(LayoutContext)


    useEffect(() => {
        setTimeout(() => {
            setData([])
            group();
        }, 50)

    }, [products]);

    const group = () => {
        if(products.length <= 0)
        {
            setData([])
            return false;
        }

        const groupedData = products.reduce((acc, item) => {
            const { id } = item;
            if (!acc[id]) {
                acc[id] = [];
            }
            acc[id].push(item);
            return acc;
        }, {});

        const groupedList = Object.values(groupedData);
        setData(groupedList)
    }

    const increaseProduct = (product) => {
        addProduct(product)
    }

    const araToplam = () => {
        let total = 0;
        data.map((product) => {
            total += product[0]?.stokFiyat * product.length
        })
        return total;
    }

    const toplamIndirim = () => {
        return 0;
    }

    const toplam = () => {
        let total = 0;
        data.map((product) => {
            total += product[0]?.stokFiyat * product.length
        })
        return total;
    }

    const toplamKdv = () => {
        let total = 0;
        data.map((product) => {
            total += product[0]?.stokKdvTutar * product.length
        })
        return total;
    }

    const genelToplam = () => {
        let total = 0;
        data.map((product) => {
            total += product[0]?.stokKdvToplam * product.length
        })
        return total;
    }

    const sendOrder = () => {
        if(user.id === 0 || user.id === undefined)
        {
            message.error("Cari bilgileri eksik devam edemezsiniz").then(r => {})
            return false;
        }
        setLoading(true)

        Api.post("/api/siparis", {cariId:user.id, stoks:products}).then(res => {
            setLoading(false)
            message.success("Siparişiniz alınmıştır").then(r => {})
            navigate("/basket/order/success/"+res.data.id, {state: {order: res.data}})
            productBasketRemoveAll()
        }).catch(err => {
            message.error(err.response.data.Detail).then(r => {})
            setLoading(false)
        })
    }

    const getPaymentType = () => {
        return(<Row gutter={[10,10]}>
            {configJS.b2b_settings.payment_credit_card === "E" &&

                <Col span={24}>
                    <Button size={"large"} className={"w-100"} onClick={() => getCreditCardPage()}>Kredi Kartı İle Ödeme</Button>
                </Col>
            }

            {configJS.b2b_settings.direct_order === "E" &&
                <Col span={24}>
                    <Button size={"large"} className={"w-100"} onClick={() => sendOrder()}>Bakiye ile Ödeme</Button>
                </Col>
            }

            {configJS.b2b_settings.bank_transfer_order === "E" &&
                <Col span={24}>
                    <Button size={"large"} className={"w-100"} >Havale ile Ödeme</Button>
                </Col>
            }

        </Row>)
    }

    const orderConfirm = () => {
        orderConfirmModal.confirm({
            title: 'Lütfen Ödeme Yöntemi Seçiniz',
            icon: <ExclamationCircleOutlined />,
            width: 1000,
            content: getPaymentType(),
            centered: true,
            footer: (_, { OkBtn, CancelBtn }) => (
                <>

                    <CancelBtn />
                </>
            ),
            cancelText: 'Vazgeç',
        });
    };

    const getCreditCardPage = () => {
        navigate("/payment", {state: {stoks: products, cariId: user.id, genelToplam: genelToplam()}});
    }


    return(<div>

        <Drawer open={creditCardModal}
                footer={[]}
                closeIcon={true}
                onClose={() => setCreditCardModal(false)}
                width={1100}
                title={"Kredi Kartı İle Ödeme"}
                destroyOnClose={true}
            >
            <PaymentPage stoks={products} cariId={user.id} genelToplam={genelToplam()}  modalClose={() => setCreditCardModal(false)}></PaymentPage>
        </Drawer>

        <div className={"basket-card mt-2 box-shadow"}>
            <div className={"card-title"}>
                <div className={"icon"}>
                    <FontAwesomeIcon icon={faBasketShopping}/>
                </div>
                <div className={"title"}>
                    Sepetim
                </div>
            </div>
            <div className={"basket-card-content"}>
                {loading && <div className={"loading"}>Yükleniyor...</div>}

                {!loading &&

                    data.map((product, index) => {
                        return (
                            <div className={"basket-card-item"} key={product[0].id}>
                                <div className={"basket-card-item-image w-10"}>
                                    <img src={product[0].stokResim} alt={product[0].stokAdi}></img>
                                </div>
                                <Link to={""} className={"basket-card-item-title w-40"}
                                      style={{position: "inherit", display: "block"}}>
                                    <div>{product[0].stokAdi}</div>
                                    <div style={{fontSize: 12}}>Stok Barkodu: {product[0].stokBarkod}</div>

                                </Link>
                                <div className={"basket-card-item-price w-10 text-align-center"}>
                                {flexCurrency(product[0].stokFiyat)} {product[0].stokParaBirimi} {product[0]?.stokKdvDurumu === "H" ? "KDV Hariç" : "KDV Dahil"}
                                </div>
                                <div className={"basket-card-item-quantity text-align-center w-30"}>
                                    <div onClick={() => decreaseProduct(product[0])} className={"negative button"}>
                                        <FontAwesomeIcon icon={faMinus}/>
                                    </div>
                                    <div className={"quantity"}>
                                        {product.filter(x => x.id === product[0].id).length}
                                    </div>
                                    <div onClick={() => increaseProduct(product[0])} className={"plus button"}>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                </div>
                                <div
                                    className={"basket-card-item-total-price text-align-center font-weight-bold w-10"}>
                                    {flexCurrency((product[0].stokFiyat * product.length).toFixed(2))} {product[0].stokParaBirimi}
                                </div>
                                <div onClick={() => removeProduct(product[0])} className={"basket-card-item-product-remove"}>
                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                </div>
                            </div>
                        )
                    })
                }

                {products.length <= 0 && !loading &&

                    <div className={"basket-card-empty"}>
                        <div className={"icon"}>
                            <FontAwesomeIcon icon={faBasketShopping}/>
                        </div>
                        <div className={"title"}>
                            Sepetinizde ürün bulunmamaktadır.
                        </div>
                    </div>

                }

            </div>
        </div>

        {products.length > 0 && !loading ?
            <div className={"basket-card mt-2 box-shadow"}>
                <div className={"card-title"}>
                    <div className={"icon"}>
                        <FontAwesomeIcon icon={faReceipt}/>
                    </div>
                    <div className={"title"}>
                        Sipariş Özeti
                    </div>
                </div>
                <div className={"basket-card-content text-align-right"}>
                    <div className={"order-item"}>
                        <div className={"label"}>Ara Toplam:</div>
                        <div className={"value"}>{flexCurrency(araToplam().toFixed(2))} {products[0]?.stokParaBirimi}</div>
                    </div>
                    <div className={"order-item"}>
                        <div className={"label"}>Toplam İndirim:</div>
                        <div className={"value"}>{flexCurrency(toplamIndirim().toFixed(2))} {products[0]?.stokParaBirimi}</div>
                    </div>
                    <div className={"order-item"}>
                        <div className={"label"}>Toplam:</div>
                        <div className={"value"}>{flexCurrency(toplam().toFixed(2))} {products[0]?.stokParaBirimi}</div>
                    </div>
                    <div className={"order-item"}>
                        <div className={"label"}>KDV:</div>
                        <div className={"value"}>{flexCurrency(toplamKdv().toFixed(2))} {products[0]?.stokParaBirimi}</div>
                    </div>
                    <div className={"order-item"}>
                        <div className={"label"}>Genel Toplam:</div>
                        <div className={"value"}>{flexCurrency(genelToplam().toFixed(2))} {products[0]?.stokParaBirimi}</div>
                    </div>


                    <div className={"order-button"}>
                        {orderContextHolder}
                        <div className={"button"} onClick={orderConfirm}>
                                <div className={"icon"}><FontAwesomeIcon icon={faBasketShopping}/></div>
                                <div className={"label"}>Siparişi Tamamla</div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div>

            </div>
        }


    </div>)

}

export default BasketPage