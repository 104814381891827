import {createContext, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";


const AuthContext = createContext();

const AuthContextProvider = ({children, userData}) => {
    const [user, setUser] = useState({});
    const navigate = useNavigate();


    const login = (user) =>{
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));

        setTimeout(() => {
            navigate("/dashboard", {replace: true});
            // window.location.href = "/dashboard";
        }, 500);
    }

    const adminLogin = (resUser) =>{
        const user = {accessToken: resUser.accessToken.token, plasiyer:true, ...resUser.detail}
        setUser(user);

        localStorage.setItem("user", JSON.stringify(user));


        setTimeout(() => {
            navigate("/admin/dashboard", {replace: true});
            // window.location.href = "/admin/dashboard";
        }, 500);
    }

    const logout = () => {
        localStorage.removeItem("user");
        setUser({});
        navigate("/login", {replace: true});
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if(user){
            setUser(user);
        }
    }, [])

    return(
        <AuthContext.Provider
            value={{
                user,
                login,
                logout,
                adminLogin
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContextProvider, AuthContext}
