import {Alert, Card, Col, message, Row} from "antd";
import Api from "../../services/Api";
import {useEffect, useState} from "react";
import axios from "axios";
import CreditCardPaymentPartial from "./partials/CreditCardPaymentPartial";
import {useLocation} from "react-router-dom";

const PaymentPage = () => {
    const [loading, setloading] = useState(true);
    const [ipAddress, setIpAddress] = useState('');
    const [data, setData] = useState();
    const { cariId, stoks, genelToplam, modalClose } = useLocation().state;

    useEffect(() => {
        getIpAdress()
    }, []);

    const getIpAdress = () => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setIpAddress(data.ip)
                getApiPaytrToken(data.ip)
            })
            .catch(err => {
                message.error("Ip adresi alınamadı").then(r => {})
            });
    }

    const getApiPaytrToken = (_ipAdress) => {
        const data = {cariId:cariId,
            cariKey:0,
            ipAdres:_ipAdress,
            siparisNo:"0",
            stokList:stoks,
            siparisTutar: genelToplam.toFixed(2), taksitTutar:0}

        Api.post("api/Payment/paytr-payment-token-generator",data).then(res => {
            setData(res.data)
            setloading(false)
            setSiparisLocalStorage(res.data.merchant_oid)

        }).catch(err => {
            message.error(err.response.data.Detail);
        })
    }

    if(cariId === 0 || cariId === undefined)
    {
        return <div className={"font-weight-bold text-align-center p-3"}>
            Cari Bilgileri eksik ödeme işlemine devam edilemez
        </div>
    }
    if(stoks.length === 0)
    {
        return <div className={"font-weight-bold text-align-center p-3"}>
            Sepetinizde ürün bulunmamaktadır
        </div>
    }
    if(genelToplam === 0)
    {
        return <div className={"font-weight-bold text-align-center p-3"}>
            Ödeme yapılacak tutar 0 olamaz
        </div>
    }

    const setSiparisLocalStorage = (siparisNo) => {
        localStorage.setItem("order", JSON.stringify({cariId:cariId, stoks:stoks, genelToplam:genelToplam, siparisNo:siparisNo}))
    }

    return(
        <div className={"mt-2"} >
            {!loading && data !== undefined &&
                <Card title={"Kredi Kartı İle Ödeme İşlemi"}>
                    <Row>
                        <Col span={24}>
                            <CreditCardPaymentPartial  data={data}></CreditCardPaymentPartial>
                        </Col>
                    </Row>
                </Card>
            }

            {loading &&
                <Card title={"Kredi Kartı İle Ödeme İşlemi"}>
                    <Row>
                        <Col span={24}>
                            <Alert message="Ödeme İşlemi Başlatılıyor" type="info" showIcon />
                        </Col>
                    </Row>
                </Card>
            }

        </div>
    )
}

export default PaymentPage