import DashboardBestSellersComponent from "./partials/DashboardBestSellersComponent";
import DashboardNewsProductsComponent from "./partials/DashboardNewsProductsComponent";
import {useContext, useEffect} from "react";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import DashboardBigSliderComponent from "./partials/DashboardBigSliderComponent";
const DashboardPage = () => {
    const {user} = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(() => {
        if(user.plasiyer){
            navigate("/admin/dashboard")
        }
    }, []);

    return (
        <>
            <div className={"section-slider"}>
                <DashboardBigSliderComponent></DashboardBigSliderComponent>
            </div>

            <div className={"section-new-product"}>
                <DashboardNewsProductsComponent></DashboardNewsProductsComponent>
            </div>

            <div className={"section-rate-product"}>
                <DashboardBestSellersComponent></DashboardBestSellersComponent>
            </div>
        </>
    );
}

export default DashboardPage;