import React, {useEffect, useState} from "react";
import Api from "../../services/Api";
import {Checkbox, Input, message, Skeleton} from "antd";
import { useParams } from "react-router-dom"
import ProductListFilterComponent from "./partials/ProductListFilterComponent";
import ProductListComponent from "./partials/ProductListComponent";

const ProductListPage = () => {
    const [products, setProducts] = useState([]);
    const [filterProducts, setFilterProducts] = useState([]);
    const [loading,setLoading] = useState(true);
    const { categoryId } = useParams();
    const [filterIsStoktakiler, setFilterIsStoktakiler] = useState(false);

    useEffect(() => {
        getProduct();
    }, []);

    const getProduct = () => {
        Api.get("/api/Stoks?PageIndex=0&PageSize=50").then(res => {
            setProducts(res.data.items)
            setFilterProducts(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Ürün bilgileri getirilirken hata oluştu.").then(err => {});
        })
    }

    // const fastFilter = (text) => {
    //     const filterProducts = products.filter(item => item.stokAdi.includes(text) || item.stokAdi.toLowerCase().includes(text.toLowerCase()) || item.stokAdi.toUpperCase().includes(text.toUpperCase()) || item.stokKodu.toLowerCase().includes(text.toLowerCase()));
    //     setFilterProducts(filterProducts);
    // }

    return (
        <div className={"container"}>
            <div className={"section-products"}>
                <ProductListFilterComponent
                    fullDataProducts={products}
                    filterProducts={filterProducts}
                    categoryId={categoryId}
                    setFilterProducts={setFilterProducts}
                    setLoading={setLoading}
                ></ProductListFilterComponent>

                <div className={"product-list-content"}>

                    <Skeleton className={"mt-1 "} active loading={loading}/>

                    {!loading &&
                        <div className={"product-list-header"}>

                            <div className={"product-list-filter"}>
                                <Checkbox onChange={(e) => setFilterIsStoktakiler(e.target.checked)}>Sadece Stoktakileri Göster</Checkbox>
                            </div>
                        </div>
                    }

                    <div className={"product-list"}>
                        <ProductListComponent filterProducts={filterProducts} filterIsStoktakiler={filterIsStoktakiler} loading={loading}></ProductListComponent>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductListPage;