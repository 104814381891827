import {Button, Card, Col, Input, message, Row, Table} from "antd";
import {useContext, useEffect, useState} from "react";
import Api from "../../../../services/Api";
import Column from "antd/es/table/Column";
import {AuthContext} from "../../../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import flexCurrency from "../../../../utils/FlexCurrency";

const AdminPlasiyerDashboardComponent = () => {
    const [cariler, setCariler] = useState([]);
    const [loading, setLoading] = useState(true);
    const {login, user} = useContext(AuthContext)
    const [searchData, setSearchData] = useState([]);
    const navigate = useNavigate();

    if(!user.plasiyer){
        navigate("/dashboard")
    }

    const getData = () => {
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "PlasiyerKodu",
                "operator": "eq",
                "value": user.plasiyerCode,
                "logic": "and"
            }
        }



        setLoading(true)
        Api.post("api/Caris/Dynamic?PageIndex=0&PageSize=100000", requestDto).then(res => {
            setCariler(res.data.items)
            setSearchData(res.data.items)
        }).catch(err => {
            message.error("Cari bilgileri alınamadı")
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        getData()
    }, []);

    const getLoginPlesiyer = (cari) => {
        setLoading(true)
        Api.post("api/Caris/CariPlesiyerAuth", {cariId:cari.id}).then(res => {
            login(res.data)
            setLoading(false)
        }).catch(err => {
            message.error("Plesiyer moduna geçilemedi")
        })
    }

    const getSearchData = (value) => {

        if(value === ""){
            setSearchData(cariler)
        }

        let filteredData = cariler.filter(item => item.cariAdi.toLowerCase().includes(value.toLowerCase()) || item.cariAdi.toUpperCase().includes(value.toUpperCase()) || item.cariAdi.includes(value) || item.cariKodu.toLowerCase().includes(value.toLowerCase()))
        setSearchData(filteredData)
    }


    return(
        <Row gutter={[10,10]} className={"mt-1"}>

            <Col span={24}>


                <Card title={"Plasiyer Moduna Geç"}>



                    <Col span={24}>
                        <Input.Search onChange={(e) => getSearchData(e.target.value)} size={"large"} placeholder={"Plasiyer girişi için cari arayın..."}></Input.Search>
                    </Col>

                    <Col span={24} className={"mt-1"}>
                        <Table rowKey={"id"} style={{overflow:"auto", height:500}} loading={loading} dataSource={searchData} size={"small"}   pagination={false}>
                            <Column title={""}
                                    width={75}
                                    render={(_,record) => {
                                        return(
                                            <Button type={"dashed"} onClick={() => getLoginPlesiyer(record)} size={"small"}>Plasiyer</Button>
                                        )
                                    }}
                                    dataIndex={"odemeYapildiMi"}></Column>
                            <Column title={"Kodu"} width={100} align={"left"}   dataIndex={"cariKodu"}></Column>
                            <Column title={"Adı"} align={"left"}   dataIndex={"cariAdi"}></Column>
                            <Column title={"Bakiye"} align={"right"}  sorter={(a, b) => a.cariBakiye - b.cariBakiye}
                                    render={(_,record) => {
                                return(
                                    <span>{flexCurrency(record.cariBakiye)}</span>
                                )
                            }}  dataIndex={"cariBakiye"}></Column>
                            <Column title={"P.Birim"} align={"right"}   dataIndex={"cariParaBirimi"}></Column>
                        </Table>

                    </Col>

                </Card>
            </Col>
        </Row>

    )
}

export default AdminPlasiyerDashboardComponent;