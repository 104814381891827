import {useContext, useEffect, useState} from "react";
import Api from "../../services/Api";
import {message, Popover, Skeleton} from "antd";
import {Link, useLocation, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import BasketQuantityModal from "../basket/BasketQuantityModal";
import flexCurrency from "../../utils/FlexCurrency";
import exChangeCurrency from "../../utils/ExChangeCurrency";

const ProductDetail = ({selectProduct}) => {
    const [product,setProduct] = useState();
    const [loading, setLoading] = useState(false);
    const [basketQuantityModal, setBasketQuantityModal] = useState({isActive: false, product: null, count: 1});
    const params= useParams()

    const getProduct = () => {
        setLoading(true)

        let selectedID = params.id;

        if(selectProduct){
            selectedID = selectProduct?.id;
        }

        Api.get("/api/Stoks/"+selectedID).then(res => {
            res.data.stokMiktar = res.data.depoHareketleri.reduce((a,b) => a + b.miktar, 0)
            setProduct(res.data);
            setLoading(false)
        }).catch(err => {
            message.error("Ürün bilgileri getirilirken hata oluştu.").then(err => {});
        });
    }

    useEffect(() => {
        // window.scrollTo(0, 0)
        getProduct();
    }, [params]);


    return(
        <div className={"container"}>

            <BasketQuantityModal
                isOpen={basketQuantityModal.isActive}
                product={basketQuantityModal.product}
                reqCount={basketQuantityModal.count}
                onClose={() => setBasketQuantityModal({isActive: false, product: null, count: 1})}
            >
            </BasketQuantityModal>

            <div className={"breadcrumb mt-2"}>
                <div className={"bread-item"}>
                    <Link to={"/dashboard"} className={"bread-name"}>
                        Mağaza
                    </Link>
                    <div className={"seperator"}>
                        /
                    </div>
                </div>
                <div className={"bread-item"}>
                    <Link to={"/products"} className={"bread-name"}>
                        Ürünler
                    </Link>
                    <div className={"seperator"}>
                        /
                    </div>
                </div>
                <div className={"bread-item"}>
                    <Link to={"/"} className={"bread-name active"}>
                        {product?.stokAdi}
                    </Link>

                </div>
            </div>

            <div className={"product-detail-content mt-2 box-shadow"}>


                <Skeleton active={true} loading={loading} className={"w-100 p-1"}></Skeleton>

                {!loading && product &&

                    <>
                        <div className={"product-image"}>
                            <img src={product?.stokResim} onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "https://vera.com.tr/wp-content/uploads/2019/11/urun-resmi-yok.png.webp"
                            }} alt={"product"}/>
                        </div>

                        <div className={"product-detail"}>
                            <div className={"product-name"}>{product?.stokAdi}</div>
                            <p>Birim: {product?.stokBirim}</p>
                            <p>Barkod: {product?.stokBarkod}</p>
                            <p>Stok Kodu: {product?.stokKodu}</p>
                            <p>Stok Miktar: <Popover placement={"rightTop"} content={<div>
                                {product?.depoHareketleri.map((item, index) => {
                                    return(
                                        <div key={index} className={"desc-item"} style={{display:"flex", width:150, justifyContent:"space-between"}}>
                                            <div className={"desc-label font-weight-bold"}>{item.depoKodu}:</div>
                                            <div>{item.miktar} Adet</div>
                                        </div>
                                    )
                                })}
                            </div>}>{product?.stokMiktar} {product?.stokBirim}</Popover></p>
                            <p>Stok Kimlik: {product?.stokKey}</p>
                            <p>Kdv Durumu: {product?.stokKdvDurumu === "H"? "KDV HARİÇ": "KDV DAHİL"}</p>
                            <p>Kdv Oranı: % {product?.stokKdv}</p>

                            {product?.stokExchangeFiyat && product?.stokExchangeFiyat.length > 0 &&
                                <div className={"price"}>{exChangeCurrency(product)}</div>
                            }

                            {!product?.stokExchangeFiyat &&
                                <div className={"price"}>{flexCurrency(product?.stokFiyat)} {product?.stokParaBirimi}</div>
                            }

                            {product?.stokMiktar > 0 &&
                                <div onClick={() => {
                                    setBasketQuantityModal({isActive: true, product: product, count: 1})
                                }} className={"add-basket mt-1"}>
                                    <div className={"icon"}><FontAwesomeIcon icon={faCartPlus}/></div>
                                    <div className={"text"}>Sepete Ekle</div>
                                </div>
                            }

                            {product?.stokMiktar <= 0 &&
                                <div className={"add-basket mt-1"} style={{backgroundColor:'gray'}}>
                                    <div className={"icon"}><FontAwesomeIcon icon={faCartPlus}/></div>
                                    <div className={"text"}>Stok Yok</div>
                                </div>
                            }

                            <div className={"desc mt-1"}>
                                <div className={"desc-label font-weight-bold"}>Açıklama:</div>
                                <div>Henüz açıklama girilmemiş</div>
                            </div>

                            <div className={"desc mt-1"}>

                            </div>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default ProductDetail;