import {
    Button,
    Collapse,
    message,
    Modal, Space, Table,

} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../../services/Api";

import ParametreDetailComponent from "./AdminParameterDetailComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

const AdminParameterPage = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [parametreModal, setParametreModal] = useState({isOpen:false, selectedParametre: null})

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        setLoading(true)
        Api.get("/api/Parametres?PageIndex=0&PageSize=100").then(res=> {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Parametreler çekilemedi")
        })
    }

    const modalClose =() => {
        setParametreModal({isOpen: false, selectedParametre: null})
        getData()
    }

    const propertyName = (key) => {
        switch (key) {
            case "b2b_settings":
                return "GENEL BİLGİLER"
            case "company":
                return "FİRMA BİLGİLERİ"
            case "theme":
                return "TEMA BİLGİLERİ"
            case "page":
                return "SAYFA BİLGİLERİ"
            case "slider_front":
                return "ANASAYFA GÖRSEL BİLGİLERİ"
            default:
                return "DİĞER"

        }
    }

    const itemChildren = (dataArr) => {
        return(
            <Table dataSource={dataArr} showHeader={true} bordered={true} size={"small"} loading={loading} pagination={false}>
            <Table.Column width={50}  title={""} dataIndex={"category"} render={(_,record) => {
                return(
                    <Space>
                        <Button size={"small"} onClick={() => setParametreModal({isOpen: true, selectedParametre: record})}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                    </Space>
                )
            }}></Table.Column>
            <Table.Column width={500} className={"font-weight-bold"} title={"Açıklama"} dataIndex={"desc"}></Table.Column>
            {/*<Table.Column title={"Kategori"} dataIndex={"category"}></Table.Column>*/}
            {/*<Table.Column title={"Kodu"} dataIndex={"code"}></Table.Column>*/}
            <Table.Column title={"Değeri"} dataIndex={"value"} render={(_,record) => {
                if(record.valueType === "editor"){
                    return "HTML"
                }

                return record.value
            }}></Table.Column>
            {/*<Table.Column title={"Değer Tipi"} dataIndex={"valueType"}></Table.Column>*/}
        </Table>
        )
    }

    const itemsArr = () => {
        if(data.length < 1){
            return []
        }

        const categories = data.map((item) => item.category)
        const uniqueCategories = [...new Set(categories)]

        return uniqueCategories.map((category, index) => {
            const categoryData = data.filter((item) => item?.category === category)
            return {key: index, label: propertyName(category), children: itemChildren(categoryData)}
        })
    }

    return(
        <>
            <Modal title={"Parametreler"} open={parametreModal.isOpen} width={750} destroyOnClose={true} footer={[]}
                   onCancel={() => setParametreModal({isOpen: false, selectedParametre: null})}>
                <ParametreDetailComponent selected={parametreModal.selectedParametre}
                                          modalClose={() => modalClose()}></ParametreDetailComponent>
            </Modal>

            <Collapse items={itemsArr()}  />

            <div className={"text-align-right mt-1"}>
                <Button onClick={() => setParametreModal({isOpen: true, selectedParametre: null})}
                        size={"small"}>Ekle</Button>
            </div>

        </>
    )
}


export default AdminParameterPage