import {Dropdown, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useContext} from "react";
import {LayoutContext} from "../../../../context/LayoutContext";


const HeaderCurrencyComponent = () => {
    const {currency, setCurrency} = useContext(LayoutContext)

    const items = [
        {
            label: 'TRY',
            key: '0',
            onClick: () => changeCurrency("TL")
        },
        {
            label: 'USD',
            key: '1',
            onClick: () => changeCurrency("USD")
        },
        {
            label: 'EUR',
            key: '2',
            onClick: () => changeCurrency("EUR")
        },

    ];

    const changeCurrency = (currency) => {
        setCurrency(currency)
    }


    return(<>
        <Dropdown
            menu={{
                items,
            }}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    {currency}
                    <DownOutlined />
                </Space>
            </a>
        </Dropdown>
    </>)
}

export default HeaderCurrencyComponent