import {Button, Checkbox, message} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../services/Api";
import {faAngleDown, faBars, faLayerGroup, faX} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const ProductListFilterComponent = ({ fullDataProducts, filterProducts, setFilterProducts, categoryId, setLoading}) => {
    const [selectedMainCategory , setSelectedMainCategory] = useState();
    const [categories, setCategories] = useState([]);
    const [mobileToggleMenuOpen, setMobileToggleMenuOpen] = useState(false);

    useEffect(() => {
        getCategory();
    }, []);

    const getCategory = () => {
        Api.get("/api/Stoks/category?PageIndex=0&PageSize=100").then(res => {
            setCategories(res.data.items)

        }).catch(err => {
            message.error("Kategori bilgileri getirilirken hata oluştu.").then(err => {});
        })
    }

    const setFilter = (isChecked, category) =>
    {
        const newCategories = categories.map(item => {
            if(item.mainCategory === category)
            {
                return { ...item, isChecked: isChecked };
            }

            const newSubCategory = item.subCategory.map(subItem => {
                if(subItem.subCategoryName === category)
                {
                    return { ...subItem, isChecked: isChecked };
                }
                return subItem;
            });

            return { ...item, subCategory: newSubCategory };
        });

        setCategories(newCategories);


        //burada checked olanları filtrele sub kategorilerde dahil olmak üzere
        const checkedCategories = newCategories.filter(item => item.isChecked === true);
        const checkedSubCategories = newCategories.map(item => item.subCategory.filter(subItem => subItem.isChecked === true)).flat()

        //filtreleme işlemini contains ile yap
        const filterProducts = fullDataProducts.filter(item => {
            if(checkedCategories.length > 0 && !checkedCategories.map(item => item.mainCategory).includes(item.stokAnaKategori))
            {
                return false;
            }

            if(checkedSubCategories.length > 0 && !checkedSubCategories.map(item => item.subCategoryName).includes(item.stokAltKategori))
            {
                return false;
            }

            return true;
        });

        setFilterProducts(filterProducts);
    }

    const getSubCategoryFilterApi = (subCategory) => {
        //buradan api'den çekeceğiz !!!
        //sonra filtremenmiş şekilde göndereceğiz!
        setLoading(true)
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "StokAnaKategori",
                "operator": "eq",
                "value": subCategory.mainCategory,
                "logic": "and",
                "filters": [
                    {
                        "field": "StokAltKategori",
                        "operator": "eq",
                        "value": subCategory.subCategoryName,
                        "logic": "and"
                    }
                ]
            }
        }

        Api.post("/api/Stoks/GetList/ByDynamic?PageIndex=0&PageSize=100000000", requestDto).then(res => {
            setFilterProducts(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Ürün bilgileri getirilirken hata oluştu.").then(err => {});
            setLoading(false)
        });
    }

    const getMainCategoryFilterApi = (mainCategory) => {
        setLoading(true)
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "StokAnaKategori",
                "operator": "eq",
                "value": mainCategory,
                "logic": "and"
            }
        }

        Api.post("/api/Stoks/GetList/ByDynamic?PageIndex=0&PageSize=100000000", requestDto).then(res => {
            setFilterProducts(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Ürün bilgileri getirilirken hata oluştu.").then(err => {});
            setLoading(false)
        });
    }

    return (
        <>
            <div className={`products-filter ${mobileToggleMenuOpen ? 'active' : ''}`}>
                <div className={"filter-item box-shadow"}>
                    <div className={"filter-label"}>Kategoriler</div>
                    <div className={"filter-content"}>
                        {categories.map((category, index) => {
                            return (
                                <div className={"checkbox-item"} key={index}>
                                    {/*<Checkbox defaultChecked={category.isChecked} onChange={(e) => {*/}
                                    {/*    setFilter(e.target.checked, category.mainCategory)*/}
                                    {/*}} type={"checkbox"} id={"category" + index} name={"category" + index}/>*/}
                                    <div className={"main-category-name"}
                                         onClick={() => {setSelectedMainCategory(category.mainCategory); getMainCategoryFilterApi(category.mainCategory)}}>{category.mainCategory.toUpperCase()}
                                        <FontAwesomeIcon style={{marginLeft: 5}} icon={faAngleDown}/></div>

                                    <div
                                        className={`sub-checkbox-item ${category.mainCategory === selectedMainCategory ? 'active' : ''}`}>
                                        {category.subCategory.length > 0 && category.subCategory.map((subCategory, index) => {
                                            if(subCategory.subCategoryName.length > 0){
                                                return (
                                                    <div key={index}>
                                                        {/*<Checkbox type={"checkbox"} id={"subCategory" + index}*/}
                                                        {/*          name={"subCategory" + index}*/}
                                                        {/*            defaultChecked={subCategory.isChecked}*/}
                                                        {/*            onChange={(e) => {*/}
                                                        {/*                setFilter(e.target.checked, subCategory.subCategoryName)}}*/}
                                                        {/*/>*/}
                                                        <div onClick={() => {getSubCategoryFilterApi(subCategory); setMobileToggleMenuOpen(false)}}
                                                             className={"sub-category-name"}>{subCategory.subCategoryName}</div>
                                                    </div>
                                                )
                                            }

                                        })}
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={"cat-menu-button-close"}>
                    <Button onClick={() => setMobileToggleMenuOpen(false)}>
                        <FontAwesomeIcon size={200} icon={faX}/>
                    </Button>
                </div>
            </div>

            <div onClick={() => setMobileToggleMenuOpen(true)} className={"cat-menu-button-open"}>
                <FontAwesomeIcon  icon={faBars} />
            </div>

        </>

    )
}

export default ProductListFilterComponent;