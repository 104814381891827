import {Button, Col, Form, Input, message, Row} from "antd";
import axios from "axios";
import Iframe from "react-iframe";
import {useEffect, useRef} from "react";
import { iframeResizer } from 'iframe-resizer';
import tokenService from "../../../services/TokenService";

const CreditCardPaymentPartial = ({data}) => {
    const iframeRef = useRef(null);
    <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>

    useEffect(() => {
        if (iframeRef.current) {
            iframeResizer({}, iframeRef.current);
        }

        // Cleanup function to remove the resizer
        return () => {
            if (iframeRef.current) {
                iframeRef.current.iFrameResizer.removeListeners();
            }
        };
    }, []);

    return (
        <div className={"p-1"}
             style={{height: '100%', width: '100%', overflow: 'hidden'}}
        >

            <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>

            {data.paytr_token_json !== undefined &&


                <iframe ref={iframeRef} id="paytriframe"
                        allowFullScreen={true}

                        style={{width: '100%', height: '100%', border: 'none', overflow: 'auto'}}
                        src={"https://www.paytr.com/odeme/guvenli/" + data.paytr_token_json}></iframe>

            }

            {data.paytr_token_json === undefined &&

                <div className={"font-weight-bold text-align-center p-3"}>
                    Ödeme işlemi başlatılamadı
                </div>
            }

        </div>)
}

export default CreditCardPaymentPartial