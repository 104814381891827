import {Button, Card, Col, message, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../../services/Api";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";

const AdminSyncPage =() => {
    const [loading,setLoading] = useState(false)
    const [data, setData] = useState([])

    const syncStock = () => {
        setLoading(true)
        Api.post("/api/Stoks/StokSync", {}).then(res => {
           message.success("Stoklar başarılı bir şekilde senkronize edildi")
            setLoading(false)
        }).catch(err => {
           message.error("Stoklar senkronize edilemedi")
        }).finally(() => setLoading(false))
    }

    const syncStockAmount = () => {
        setLoading(true)
        Api.post("/api/Stoks/StokMiktarSync", {}).then(res => {
           message.success("Stok miktarları başarılı bir şekilde senkronize edildi")
            setLoading(false)
        }).catch(err => {
           message.error("Stok miktarları senkronize edilemedi")
        }).finally(() => setLoading(false))
    }

    const syncCustomer = () => {
        setLoading(true)
        Api.post("/api/Caris/CariSync", {}).then(res => {
           message.success("Cari hesaplar başarılı bir şekilde senkronize edildi")
            setLoading(false)
        }).catch(err => {
           message.error("Cari hesaplar senkronize edilemedi")
        }).finally(() => setLoading(false))
    }

    const syncWarehouse = () => {
        setLoading(true)
        Api.post("/api/Depoes/DepoSync", {}).then(res => {
           message.success("Depolar başarılı bir şekilde senkronize edildi")
            setLoading(false)
        }).catch(err => {
           message.error("Depolar senkronize edilemedi")
        }).finally(() => setLoading(false))
    }

    const getData = () => {
        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate",
                    "dir": "desc"
                }
            ]
        }
        setLoading(true)


        Api.post("api/SyncLogs/dynamic?PageIndex=0&PageSize=100",requestDto ).then(res => {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Veriler alınamadı")
        })
    }

    useEffect(() => {
        getData()
    }, []);


    return(<div>
        <Card loading={loading}>
            <Row gutter={[10,10]}>
                <Col >
                    <Button onClick={() => syncStock()}> Stokları Manuel Senkronize Et</Button>
                </Col>
                <Col >
                    <Button onClick={() => syncStockAmount()}> Stok Miktarlarını Manuel Senkronize Et</Button>
                </Col>
                <Col>
                    <Button onClick={() => syncCustomer()}> Cari Hesapları Manuel Senkronize Et</Button>
                </Col>
                <Col >
                    <Button onClick={() => syncWarehouse()}> Depoları Manuel Senkronize Et</Button>
                </Col>
            </Row>

            <Row gutter={[10,10]}>
               <Col span={24}>
                   <Table showHeader={true} rowKey={"id"} loading={loading} dataSource={data} size={"small"} className={"mt-1"} pagination={true}>

                       <Column title={"Zaman"} align={"left"}  dataIndex={"syncDate"} render={(_,record) => {
                           return dayjs(record.syncDate).format("DD/MM/YYYY HH:mm")
                       }}>

                       </Column>
                       <Column title={"Tipi"} dataIndex={"syncType"}></Column>
                       <Column title={"Adı"}  dataIndex={"entityName"}></Column>
                       <Column title={"Durum"}  dataIndex={"syncStatus"}></Column>
                       <Column title={"Mesaj"}  dataIndex={"syncMessage"}></Column>

                   </Table>
               </Col>
            </Row>
        </Card>

    </div>)
}

export default AdminSyncPage