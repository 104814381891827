import {Button, Card, Col, Form, Input, Row} from "antd";
import Iframe from "react-iframe";

const IletisimPage = () => {
    const [form] = Form.useForm();
    const onFinish = (values) => {

    }
    return(
        <div className={"mt-1"}>
            <Card title={"İletişim"}>
                <Form
                    layout={"horizontal"}
                    form={form}
                    colon={false}
                    onFinish={onFinish}
                    labelCol={{
                        span: 10,
                    }}
                    labelAlign={"left"}


                >
                    <Row gutter={[10,10]}>
                        <Col span={24} xl={12}>
                            <Row gutter={[10,10]}>
                               <Col span={24}>
                                   <Form.Item label={"Adınız Soyadınız"} name={"adSoyad"} rules={[{ required: true },]}>
                                       <Input></Input>
                                   </Form.Item>
                               </Col>

                                <Col span={24}>
                                    <Form.Item label={"Telefon"} name={"adSoyad"} rules={[{ required: true },]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item label={"Eposta"} name={"adSoyad"} rules={[{ required: true },]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item label={"Mesajınız"} name={"adSoyad"} rules={[{ required: true },]}>
                                        <Input.TextArea rows={4}></Input.TextArea>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item label={"Mesajınız"} name={"adSoyad"} rules={[{ required: true },]}>
                                        <Button>Gönder</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} xl={12}>
                            <Iframe
                                frameBorder={0}
                                width={"100%"} height={"100%"}
                                style="border:0;" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade" url={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11240.12977882586!2d37.87403363979121!3d40.98765673061912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40632035e75db969%3A0x5249f64bf6712233!2zMlRla25payBZYXrEsWzEsW0!5e0!3m2!1str!2str!4v1720979340018!5m2!1str!2str"}></Iframe>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    )
}

export default IletisimPage