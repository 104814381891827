const FlexCurrency = (number) => {
    if(number === undefined || number === null || isNaN(number))
        return 0;
    else{
        return new Intl.NumberFormat('tr-TR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    }
};

export default FlexCurrency;