import {useEffect, useState} from "react";
import Api from "../../../../services/Api";
import {Button, Input, message, Table} from "antd";

const AdminDiaSettings = () => {
    const [data,setData] = useState([]);

    const getData = () => {
        Api.get("/api/AppSettings/GetListAppSettings?section=DiaSettingsConfig").then(res => {
            setData(res.data.items);
        }).catch(err => {
            message.error("Sayfa getirilirken hata oluştu").then(err => {});
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const updateData = (record) => {
        Api.post("/api/AppSettings/SetConfigAppSettings",record).then(res => {
            message.success("Güncelleme başarılı").then(r => {});
        }).catch(err => {
            message.error("Güncelleme sırasında hata oluştu").then(r => {});
        })
    }

    return(
        <Table
            showHeader={true}
            pagination={false}
            dataSource={data}
        >
            {/*<Table.Column dataIndex={"section"} title={"Sektör"}></Table.Column>*/}
            <Table.Column dataIndex={"key"} title={"Parametre"}></Table.Column>
            <Table.Column dataIndex={"value"} title={"Değer"} render={(_,record) => {
                return(
                    <Input value={record.value} onChange={(e) => {
                        setData(data.map(item => {
                            if(item.key === record.key){
                                item.value = e.target.value;
                            }
                            return item;
                        }))
                    }}></Input>
                )
            }}></Table.Column>
            <Table.Column render={(_,record) => {
                return(
                    <Button onClick={(e) => {
                        updateData(record)
                    }}>Güncelle</Button>
                )
            }}></Table.Column>


        </Table>
    )
}

export default AdminDiaSettings