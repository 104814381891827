import React, {useContext, useEffect, useRef, useState} from "react";
import {Drawer} from "antd";
import ProductDetail from "../ProductDetail";
import flexCurrency from "../../../utils/FlexCurrency";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import BasketQuantityModal from "../../basket/BasketQuantityModal";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {LayoutContext} from "../../../context/LayoutContext";
import exChangeCurrency from "../../../utils/ExChangeCurrency";

const ProductListComponent = React.memo(({ filterProducts, loading, filterIsStoktakiler}) => {
    const [displayedProducts, setDisplayedProducts] = useState([]);
    const loadMoreRef = useRef(null);
    const [loadMoreCount, setLoadMoreCount] = useState(100);
    const [productDetailModal, setProductDetailModal] = useState({isActive: false, product: null});
    const [basketQuantityModal, setBasketQuantityModal] = useState({isActive: false, product: null, count: 1});
    const {currency} = useContext(LayoutContext);

    useEffect(() => {
        setDisplayedProducts(filterProducts.slice(0, loadMoreCount));

        if(filterIsStoktakiler){
            setDisplayedProducts(filterProducts.filter(item => item.stokMiktar > 0).slice(0, loadMoreCount));
        }

    }, [filterProducts, loadMoreCount, filterIsStoktakiler]);


    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setLoadMoreCount(prevCount => prevCount + 100);
            }
        }, { threshold: 1.0 });

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, []);

    // const exChangeCurrency = (product) => {
    //     const fiyat = product.stokExchangeFiyat.find(item => item.paraBirimi === currency)?.fiyat || 0;
    //     return <>{flexCurrency(fiyat)} {currency}</>
    // }

    return (
        <>
            <BasketQuantityModal
                isOpen={basketQuantityModal.isActive}
                product={basketQuantityModal.product}
                reqCount={basketQuantityModal.count}
                onClose={() => setBasketQuantityModal({isActive: false, product: null, count: 1})}
            >
            </BasketQuantityModal>

            <Drawer
                footer={[]}
                open={productDetailModal.isActive}
                destroyOnClose={true}
                placement={"top"}
                height={"100%"} onClose={() => setProductDetailModal({isActive: false, product: null})}>
                <ProductDetail selectProduct={productDetailModal.product}></ProductDetail>
            </Drawer>


            {!loading &&
                displayedProducts.map((product, index) => (
                    <div className={"product-card box-shadow"} key={index}>
                        <div className={"product-image"}>
                            <LazyLoadImage
                                src={product.stokResim}
                                alt="Ürün Adı"
                                onClick={() => setProductDetailModal({isActive: true, product: product})}
                                effect="blur"
                                width="100%"
                                height="100%"
                                placeholderSrc="https://vera.com.tr/wp-content/uploads/2019/11/urun-resmi-yok.png.webp"
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents loop if placeholder fails
                                    e.target.src = "https://vera.com.tr/wp-content/uploads/2019/11/urun-resmi-yok.png.webp";
                                }}
                            />
                        </div>
                        <div className={"product-detail"}>
                            <div className={"product-name"}>
                                {product.stokAdi} {product.stokMiktar}
                            </div>
                            <div className={"product-stock-code"}>
                                {product.stokKodu}
                            </div>
                            <div className={"product-price"}>

                                {/*{flexCurrency(product.stokFiyat)} {product.stokParaBirimi}*/}
                                {exChangeCurrency(product)}


                            </div>

                            {product.stokMiktar > 0 &&
                                <div
                                    onClick={() => setBasketQuantityModal({
                                        isActive: true,
                                        product: product,
                                        count: 1
                                    })}
                                    className={"product-button"}>
                                    <div className={"product-button-icon"}><FontAwesomeIcon icon={faCartPlus}/>
                                    </div>
                                    <div className={"product-button-label"}>Sepete Ekle</div>
                                </div>
                            }

                            {product.stokMiktar <= 0 &&
                                <div className={"product-button-disabled"}>
                                    <div className={"product-button-icon"}><FontAwesomeIcon icon={faCartPlus}/>
                                    </div>
                                    <div className={"product-button-label"}>Stokta Yok</div>
                                </div>
                            }

                        </div>
                    </div>
                ))
            }
            <div ref={loadMoreRef} style={{height: "20px"}}></div>
        </>
    );
});


export default ProductListComponent;