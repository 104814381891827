import {Button, Col, Form, Input, message, Row, Space} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../../services/Api";

const ParametreDetailComponent = ({selected, modalClose}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true)

    const getData = () => {
        if(selected?.id > 0 ){
            Api.get("/api/Parametres/"+selected.id).then(res => {
                form.setFieldsValue(res.data)
            }).catch(err => {
                message.error("Veriler yüklenemedi")
            })
        }
    }

    useEffect(() => {
        getData()
    },[])

    const onFinish = (values) => {
        if(values.id === undefined || values.id === null || values.id === ""){
            values.id = 0;
            Api.post("/api/Parametres", values).then(res => {
                message.success("Parametre eklendi")
                modalClose()
            }).catch(err => {
                message.error("Parametre eklenemedi")
            })
        }

        else if(values.id > 0){
            Api.put("/api/Parametres", values).then(res => {
                message.success("Parametre güncellendi")
                modalClose()
            }).catch(err => {
                message.error("Parametre güncellenemedi")
            })
        }

    }

    const deleteData = () => {
        setLoading(true)
        Api.delete("/api/Parametres/"+selected.id).then(res => {
            message.success("Parametre silindi")
            modalClose()
            setLoading(false)
        }).catch(err => {
            message.error("Parametre silinemedi")
        })
    }

    return (
        <div>
            <Form
                layout={"vertical"}
                form={form}
                colon={false}
                onFinish={onFinish}
                labelCol={{
                    span: 10,
                }}
                labelAlign={"left"}
            >
                <Row gutter={[10,10]}>

                    <Form.Item hidden={true} name={"id"}>
                        <Input></Input>
                    </Form.Item>

                    <Col span={24}>
                        <Form.Item label={"Kategori"} name={"category"} rules={[{ required: true },]}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={"Kodu"} name={"code"} rules={[{ required: true },]}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={"Değer"} name={"value"} rules={[{ required: true },]}>
                            <Input.TextArea></Input.TextArea>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={"Değer Tipi"} name={"valueType"} rules={[{ required: true },]}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={"Açıklama"} name={"desc"} rules={[{ required: true },]}>
                            <Input.TextArea></Input.TextArea>
                        </Form.Item>
                    </Col>

                    <Col span={24} className={"mt-1 text-align-right"}>
                        <Space>
                            {selected?.id > 0 &&
                                <Button type={"dashed"} onClick={() => deleteData()} >Sil</Button>
                            }

                            <Button type={"primary"}  htmlType="submit" >Kaydet</Button>

                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ParametreDetailComponent;
