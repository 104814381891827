import {Button, Card, Col, message, Modal, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import Column from "antd/es/table/Column";
import {useEffect, useState} from "react";
import Api from "../../../../services/Api";
import {Link} from "react-router-dom";
import {DeleteOutlined, FormOutlined} from "@ant-design/icons";
import AdminUserDetailPage from "./AdminUserDetailPage";

const AdminUserListPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userDetailModal, setUserDetailModal] = useState({isActive:false, selectedUserId:null})

    const getData = () => {
        setLoading(true)
        Api.get("/api/Users?PageIndex=0&PageSize=100").then(res => {
            res.data.items = res.data.items.filter(item => item.email !== "admin@admin.com")
            setData(res.data.items)
        }).catch(err => {
            message.error("Cari bilgileri alınamadı")
        }).finally(() => setLoading(false))
    }

    const newUserModalOpen = () => {
        setUserDetailModal({isActive:true, selectedUserId:null})
    }

    const deleteUser = (id) => {
        Api.delete("/api/Users/",{data:{id:id}}).then(res => {
            message.success("Kullanıcı başarılı bir şekilde silindi")
            getData()
        }).catch(err => {
            message.error("Kullanıcı silinemedi")
        })

    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <div>
            <Modal open={userDetailModal.isActive}
                   centered={true}
                   title={"Kullanıcı"}
                   width={1000}
                   footer={[]}
                   destroyOnClose={true}
                   onCancel={() => {setUserDetailModal({isActive: false, selectedUserId: null})}}>
                <AdminUserDetailPage data={userDetailModal} onClose={() => {setUserDetailModal({isActive: false, selectedUserId: null}, getData())}}></AdminUserDetailPage>
            </Modal>

            <Row>
                <Col span={24}>
                    <Card >

                        <Button onClick={() => newUserModalOpen()} type={"primary"}>Yeni Kullanıcı Ekle</Button>
                        <Table showHeader={false} rowKey={"id"} loading={loading} dataSource={data} size={"small"} className={"mt-1"} pagination={true}>
                            <Column title={""} width={50} dataIndex={""} render={(_, record) => {
                                return(
                                    <Space>
                                        <Tooltip title="Kullanıcı Düzenle">
                                            <Button icon={<FormOutlined />}  size={"small"}
                                                    onClick={() => {setUserDetailModal({isActive:true, selectedUserId: record.id})}} state={{userId:record.id}}
                                            >

                                            </Button>
                                        </Tooltip>

                                        <Popconfirm
                                            placement="top"
                                            title={"Silme Onayı"}
                                            description={"Bu kullanıcının silinmesini istiyor musunuz?"}
                                            onConfirm={() => deleteUser(record.id)}
                                            okText="Evet"
                                            cancelText="Hayır"
                                        >
                                            <Button size={"small"} icon={<DeleteOutlined />} ></Button>
                                        </Popconfirm>
                                    </Space>
                                )
                            }}></Column>
                            {/*<Column title={"Id"} width={200}  dataIndex={"id"}></Column>*/}
                            <Column title={"Kullanıcı Adı"}  dataIndex={"email"}></Column>
                        </Table>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default AdminUserListPage