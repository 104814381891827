import {SearchOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {Form, message} from "antd";
import Api from "../../../../services/Api";
import {useNavigate} from "react-router-dom";
import flexCurrency from "../../../../utils/FlexCurrency";

const HeaederProductSearchComponent = () => {
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const navigate = useNavigate();
    const searchRef = useRef(null);

    const getProduct = () => {

        if(searchText.length < 3){
            return;
        }

        const requestDto = {
            "sort": [
                {
                    "field": "CreatedDate.Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "StokAdi",
                "operator": "contains",
                "value": searchText,
                "logic": "or",
                "filters": [
                    {
                        "field": "StokBarkod",
                        "operator": "contains",
                        "value": searchText,
                        "logic": "or"
                    },
                    {
                        "field": "StokKodu",
                        "operator": "contains",
                        "value": searchText,
                        "logic": "or"
                    }
                ]
            }
        }


        Api.post("/api/Stoks/GetList/ByDynamic?PageIndex=0&PageSize=10",requestDto).then(res => {
            setProducts(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Ürün bilgileri getirilirken hata oluştu.").then(err => {});
        })
    }

    // useEffect(() => {
    //     getProduct();
    // }, []);

    useEffect(() => {
        // getProduct(searchText)
    },[searchText])


    // const getFilter = (text) => {
    //     const filterProducts = products.filter(item => item.stokAdi.includes(text) || item.stokAdi.toLowerCase().includes(text.toLowerCase()) || item.stokAdi.toUpperCase().includes(text.toUpperCase())|| item.stokKodu.toLowerCase().includes(text.toLowerCase()));
    //
    //     if(filterProducts.length > 10){
    //         setFilterProducts(filterProducts.slice(0,10))
    //     }
    //
    //     setFilterProducts(filterProducts);
    // }


    const onFinish = (values) => {
        if(searchText.length < 3){
            message.error("Arama yapabilmek için en az 3 karakter giriniz.").then(r => {})
            return;
        }
        searchRef.current.value = ""
        navigate("/products/search/"+searchText)

    }

    const getItem = (item) => {
        // setFilterProducts([])
        setSearchText("")
        searchRef.current.value = ""
        navigate("/products/detail/"+item.id, {state: {id: item.id}})
    }

    return (
        <Form onFinish={onFinish}>
            <div className={"search"}>
                <input ref={searchRef} type={"text"}
                       className={"search-text"}
                       onChange={(e) => setSearchText(e.target.value)}
                       placeholder={"Bir ürün mü arıyorsunuz?"}>
                </input>
                <button type={"submit"} className={"search-button"}>
                    <div className={"search-icon"}><SearchOutlined/></div>
                    <div className={"search-label"}>Ara</div>
                </button>

                {searchText.length > 3 && products.length > 0 &&
                    <div className={"search-result-content"}>
                        <ul>

                            {products.map((item, index) => {
                                return (
                                    <li onClick={() => getItem(item)} key={index}>
                                        <div className={"product-title"}>{item.stokAdi}</div>
                                        <div className={"product-price font-weight-bold"}>{flexCurrency(item.stokFiyat.toFixed(2))} {item.stokParaBirimi}</div>
                                        {/*<div className={"product-add-basket"}>Sepete Ekle</div>*/}
                                    </li>
                                )
                            })}

                        </ul>
                    </div>
                }

            </div>

        </Form>

    )
}

export default HeaederProductSearchComponent;